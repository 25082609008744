import SearchIcon from '@mui/icons-material/Search';
import 'flatpickr/dist/themes/material_blue.css';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Flatpickr from 'react-flatpickr';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, InputGroup, Modal, Row } from 'reactstrap';
import { get } from '../../../helpers/api_helper';
import { getDbDate } from '../../../helpers/date_helper';
import { getAllEvent } from '../../../store/event/actions';

function removeBodyCss() {
  document.body.classList.add('no_padding');
}
export const EventSearch = ({ inputs, setInputs }) => {
  let selectStart;
  if (inputs.startDate) {
    selectStart = new Date(inputs.startDate);
    selectStart.setDate(selectStart.getDate() + 1);
  }

  const [modal_center, setmodal_center] = useState(false);
  const [allCat, setAllCat] = useState([]);
  const dispatch = useDispatch();
  const { currentPage, perPage } = useSelector(({ pagination }) => pagination);
  useEffect(() => {
    get(`/api/v1/breed/category/all`)
      .then((responce) => {
        setAllCat(responce.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    //alert(value);
    if (name === 'search_text') {
      setInputs((values) => ({ ...values, [name]: value.trim() }));
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };
  const handleChangeSD = (val) => {
    let startDate = document.getElementById('StartFlatpickr').value;
    setInputs((values) => ({ ...values, startDate: startDate }));
  };
  const handleChangeED = (val) => {
    let endDate = document.getElementById('EndFlatpickr').value;
    setInputs((values) => ({ ...values, endDate: endDate }));
  };
  let handleSearch = (value = '', isSearch = false) => {
    //alert(1);
    let data = {};
    if (inputs.search !== '') {
      data.search_text = inputs.search.trim();
    }
    if (isSearch) {
      data.search_text = value.trim();
    }
    if (inputs.startDate) {
      data.start_date = getDbDate(inputs.startDate);
    }
    if (inputs.endDate) {
      data.end_date = getDbDate(inputs.endDate);
    }
    if (inputs.active1 !== 'both') {
      data.event_type = inputs.active1;
    }
    if (inputs.active2 !== 'both') {
      data.show_type = inputs.active2;
    }
    if (inputs.active3 !== 'both') {
      data.show_category = inputs.active3;
    }
    if (inputs.active4 !== 'all') {
      data.breed_category_id = inputs.active4;
    }

    dispatch(getAllEvent(1, perPage, data));
  };

  let handleFilter = () => {
    let startDate = document.getElementById('StartFlatpickr').value;
    if (startDate) {
      setInputs((values) => ({ ...values, startDate: startDate }));
    }
    let endDate = document.getElementById('EndFlatpickr').value;
    if (endDate) {
      setInputs((values) => ({ ...values, endDate: endDate }));
    }
    //alert(inputs.startDate);
    //alert(inputs.endDate);
    console.log(inputs);
    let data = {};
    if (inputs.search !== '') {
      data.search_text = inputs.search;
    }
    if (inputs.startDate) {
      data.start_date = inputs.startDate
        ? getDbDate(inputs.startDate)
        : getDbDate(startDate);
    }
    if (inputs.endDate) {
      data.end_date = inputs.endDate
        ? getDbDate(inputs.endDate)
        : getDbDate(endDate);
    }
    if (inputs.active1 !== 'both') {
      data.event_type = inputs.active1;
    }
    if (inputs.active2 !== 'both') {
      data.show_type = inputs.active2;
    }
    if (inputs.active3 !== 'both') {
      data.show_category = inputs.active3;
    }
    if (inputs.active4 !== 'all') {
      data.breed_category_id = inputs.active4;
    }

    let validationFlag = false;
    if (startDate && !endDate) {
      validationFlag = true;
      toast.error('Please select end date', { toastId: 1 });
    }
    if (!startDate && endDate) {
      validationFlag = true;
      toast.error('Please select start date', { toastId: 1 });
    }
    if (!validationFlag) {
      dispatch(getAllEvent(1, perPage, data));
      setmodal_center(false);
    }
    // else {

    // }
  };

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }
  return (
    <React.Fragment>
      <div class="col-md-12 mb-7">
        <div class="card shadow-only-hover1">
          <div class="card-body event-card-body">
            <div class="col-lg-12 col-xl-12 event-box">
              <div class="row">
                <div class="col-lg-10 col-xl-10 box-head mb-4 res-p-r">
                  Search{' '}
                  <input
                    type="text"
                    name="search"
                    value={inputs.search}
                    onChange={(e) => {
                      handleChange(e);
                      handleSearch(e.target.value, true);
                    }}
                    placeholder="Search by typing Event Name or City or State."
                    class="search-input"
                  />
                  <SearchIcon
                    className="search-btn"
                    onClick={() => {
                      handleSearch();
                    }}
                  />
                </div>
                <div className="col-lg-2 col-xl-2 box-head mb-4">
                  <button
                    type="button"
                    className="btn btn-primary text-left w-100 flt-btn"
                    onClick={() => {
                      tog_center();
                    }}
                    data-toggle="modal"
                    data-target=".bs-example-modal-center"
                  >
                    Filter{' '}
                    <i
                      className="fa fa-filter filter-icon"
                      style={{ marginLeft: '50px' }}
                    ></i>
                  </button>
                  <Modal
                    className="filter-modal"
                    isOpen={modal_center}
                    toggle={() => {
                      tog_center();
                    }}
                    centered={true}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">Filter</h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_center(false);
                        }}
                        className="close-btn"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Row className="mb-4">
                        <Col lg={2}>
                          <label class="form-check-label mt-3 " for="check1">
                            Start Date:
                          </label>
                        </Col>
                        <Col lg={4}>
                          <div className="">
                            <InputGroup>
                              <Flatpickr
                                id="StartFlatpickr"
                                className="form-control d-block glyphicon glyphicon-calendar"
                                placeholder={`Start Date`}
                                name="startDate"
                                options={{
                                  minDate: '2018-01-01',
                                  dateFormat: 'm-d-Y',
                                  maxDate: new Date(inputs.endDate) - 1,
                                }}
                                value={inputs.startDate ? inputs.startDate : ''}
                                style={{
                                  width: '100%',
                                }}
                                onChange={handleChangeSD}
                              />
                              <i className="fa fa-calendar cal-icon"></i>
                            </InputGroup>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <label class="form-check-label mt-3 " for="check1">
                            End Date :
                          </label>
                        </Col>
                        <Col lg={4}>
                          <div className="">
                            <InputGroup>
                              <Flatpickr
                                id="EndFlatpickr"
                                className="form-control d-block"
                                placeholder={`End Date`}
                                options={{
                                  dateFormat: 'm-d-Y',
                                  minDate: selectStart,
                                }}
                                value={inputs.endDate ? inputs.endDate : ''}
                                style={{
                                  width: '100%',
                                }}
                                onChange={handleChangeED}
                              />
                              <i className="fa fa-calendar cal-icon"></i>
                            </InputGroup>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <Col lg={2} className="res-mb-2">
                          {' '}
                          <label
                            className="form-check-label mt-1 "
                            for="check1"
                          >
                            Event Type :{' '}
                          </label>
                        </Col>
                        <Col lg={3} className="res-mb-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="active1"
                            id="active1"
                            onChange={handleChange}
                            value="national"
                            checked={`${
                              inputs.active1 === 'national' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className="form-check-label pr-10 mr-5"
                            for="active1"
                          >
                            National
                          </label>
                        </Col>
                        <Col lg={3} className="res-mb-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="active1"
                            id="active2"
                            value="non-national"
                            onChange={handleChange}
                            checked={`${
                              inputs.active1 === 'non-national' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className="form-check-label pr-10 mr-5"
                            for="active2"
                          >
                            Non-National
                          </label>
                        </Col>
                        <Col lg={3} className="res-mb-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="active1"
                            id="active3"
                            onChange={handleChange}
                            value="both"
                            checked={`${
                              inputs.active1 === 'both' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className="form-check-label pr-10 mr-5"
                            for="active3"
                          >
                            Both
                          </label>
                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <Col lg={2} className="res-mb-2">
                          {' '}
                          <label
                            className="form-check-label mt-1 "
                            for="check4"
                          >
                            Show Type :{' '}
                          </label>
                        </Col>
                        <Col lg={3} className="res-mb-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="active2"
                            id="active4"
                            value="open"
                            onChange={handleChange}
                            checked={`${
                              inputs.active2 === 'open' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className="form-check-label pr-10 mr-5"
                            for="active4"
                          >
                            Open
                          </label>
                        </Col>
                        <Col lg={3} className="res-mb-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="active2"
                            id="active22"
                            value="youth"
                            onChange={handleChange}
                            checked={`${
                              inputs.active2 === 'youth' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className="form-check-label pr-10 mr-5"
                            for="active22"
                          >
                            Youth
                          </label>
                        </Col>
                        <Col lg={3} className="res-mb-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="active2"
                            id="active5"
                            value="both"
                            onChange={handleChange}
                            checked={`${
                              inputs.active2 === 'both' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className="form-check-label pr-10 mr-5"
                            for="active5"
                          >
                            Both
                          </label>
                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <Col lg={2} className="res-mb-2">
                          {' '}
                          <label
                            className="form-check-label mt-1 "
                            for="check1"
                          >
                            Specialty :{' '}
                          </label>
                        </Col>
                        <Col lg={3} className="res-mb-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="active3"
                            id="active6"
                            value="non-speciality"
                            onChange={handleChange}
                            checked={`${
                              inputs.active3 === 'non-speciality'
                                ? 'checked'
                                : ''
                            }`}
                          />
                          <label
                            className="form-check-label pr-10 mr-5"
                            for="active6"
                          >
                            Non-specialty
                          </label>
                        </Col>
                        <Col lg={3} className="res-mb-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="active3"
                            id="active7"
                            value="speciality"
                            onChange={handleChange}
                            checked={`${
                              inputs.active3 === 'speciality' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className="form-check-label pr-10 mr-5"
                            for="active7"
                          >
                            Specialty
                          </label>
                        </Col>
                        <Col lg={3} className="res-mb-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="active3"
                            id="active9"
                            value="both"
                            onChange={handleChange}
                            checked={`${
                              inputs.active3 === 'both' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className="form-check-label pr-10 mr-5"
                            for="active9"
                          >
                            Both
                          </label>
                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <Col lg={2} className="res-mb-2">
                          {' '}
                          <label
                            className="form-check-label mt-1 "
                            for="check1"
                          >
                            Category :{' '}
                          </label>
                        </Col>
                        <Col lg={3} className="mb-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="active4"
                            id="active8"
                            value="all"
                            onChange={handleChange}
                            checked={`${
                              inputs.active4 === 'all' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className="form-check-label pr-10 mr-5"
                            for="active8"
                          >
                            All Categories
                          </label>
                        </Col>

                        {allCat?.map((item, index) => {
                          let margin = 0;
                          if ((index + 1) % 3 === 0) {
                            margin = 150;
                          }
                          return (
                            <Col
                              lg={3}
                              style={{
                                marginLeft: `${margin}px`,
                              }}
                              className="res-mb-2 res-ml-0"
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="active4"
                                id={`active${index + 10}`}
                                value={item.breed_category_id}
                                onChange={handleChange}
                                checked={`${
                                  inputs.active4 == item.breed_category_id
                                    ? 'checked'
                                    : ''
                                }`}
                              />

                              <label
                                className="form-check-label pr-10 mr-5"
                                for={`active${index + 10}`}
                              >
                                {item.category_name}
                              </label>
                            </Col>
                          );
                        })}
                      </Row>
                      <Col lg={12} className="text-center">
                        <button
                          type="button"
                          class="btn btn-primary btn-easy"
                          onClick={handleFilter}
                        >
                          APPLY
                        </button>
                      </Col>
                    </div>
                  </Modal>
                </div>
                <div class="col-lg-6 col-xl-6 box-head text-left">
                  {' '}
                  <i class="fa fa-circle icon-dot"></i> Only Events that offer
                  Online entry appear here.
                </div>
                <div class="col-lg-6 col-xl-6 box-head text-left">
                  <i class="fa fa-circle icon-dot"></i> Some Events may require
                  you to pay your entries Online.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};