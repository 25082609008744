import { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const GRecaptcha = ({ setGRC }) => {
  const ReCaptchaRef = useRef(null);
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const grecaptchaObject = window.grecaptcha;
  useEffect(() => {
    setGRC(ReCaptchaRef);
  }, [grecaptchaObject, ReCaptchaRef]);
  return (
    <ReCAPTCHA
      type="image"
      sitekey={siteKey}
      size="invisible"
      ref={ReCaptchaRef}
    />
  );
};
export default GRecaptcha;
