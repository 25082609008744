import React from 'react'
import ContactUs from './Contact/ContactUs'

const Footer = () => {
  return (
    <React.Fragment>
      <footer className='footer-page'>
        <div>
          <div className='row'>
            <div className='col-md-10'>
              <div className='text-left d-sm-block'>
                © {new Date().getFullYear()} Easy2Show. All Rights Reserved.
              </div>
            </div>
            <ContactUs />
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Footer