import { ALL_STATE_LIST_SUCCESS } from './actionType'

//

const INIT_STATE = {
  StateList: [],
  loading: true,
  error: {},
}

const StateListReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case ALL_STATE_LIST_SUCCESS:
      return {
        ...state,
        StateList: payload,
        loading: false,
      }

    default:
      return state
  }
}

export default StateListReducer
