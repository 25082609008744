import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './assets/scss/theme.scss'
import AuthLayout from './components/AuthLayout'
// layouts Format
import HorizontalLayout from './components/HorizontalLayout/'
// Import Routes all
import { authRoutes, easyfidesRoutes, UnAuthRoutes } from './routes/allRoutes'
// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware'
import {
  easyFidesProfile,
  getAllEventCart,
  getAllShopCartValue,
  resetPage,
  sanctionShoppingCart,
} from './store/actions'
import { withOneTabEnforcer } from 'react-one-tab-enforcer'
import DifferentWarningComponent from './pages/DifferentWarningComponent'
import EasyFiedsLayout from './components/AuthLayout/Easyfides/EasyFiedsLayout'
// import { Modal, ModalBody } from 'reactstrap'

const App = (props) => {
  let location = useLocation()

  // let path = location.pathname.split('/')
  const query = new URLSearchParams(location.search)
  // const [showModal, setShowModal] = useState(false)

  const logout = query.get('logout')
  const secretaryId = query.get('secretary')
  const dispatch = useDispatch()

  const user = JSON.parse(localStorage.getItem('authUser'))
  // const modal = JSON.parse(localStorage.getItem('modal'))
  const userData = useSelector((state) => state.Login)

  useEffect(() => {
    dispatch(resetPage())
  }, [location?.pathname])
  useEffect(() => {
    if (logout) {
      window.history.pushState(null, document.title, window.location.href)
      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, '#')
      })
    }
  }, [])

  let data = {
    user_id: user?.id,
  }

  // useEffect(() => {
  //   const handleTabClose = (event) => {
  //     event.preventDefault();
  //     console.log('beforeunload event triggered');
  //     return localStorage.removeItem('purchaseInfo');
  //   };
  //   window.addEventListener('unload', handleTabClose);
  //   return () => {
  //     window.removeEventListener('unload', handleTabClose);
  //   };
  // }, []);

  useEffect(() => {
    dispatch(getAllShopCartValue(data))
    dispatch(getAllEventCart(user?.id))
    dispatch(easyFidesProfile())
    // setInterval(() => {
    dispatch(sanctionShoppingCart())
    // }, 3000);

    toast.dismiss()
  }, [])

  // useEffect(() => {

  //   if (!modal) {
  //     setShowModal(true)
  //   }

  // }, [location?.pathname, modal])
  // useEffect(() => {}, [path])

  function getLayout() {
    let layoutCls = HorizontalLayout

    return layoutCls
  }

  const Layout = getLayout()

  // function getAuthLayout() {
  //   let path = location.pathname.split('/')
  //   let layoutCls = AuthLayout

  //   if (path.includes('easyfides')) {
  //     layoutCls = EasyFiedsLayout
  //   }

  //   return layoutCls
  // }
  const getAuthLayout = useCallback(() => {
    let layoutCls = AuthLayout

    return layoutCls
  }, [location.pathname])

  if (
    localStorage.getItem('secretaryToken') &&
    !Boolean(logout) &&
    !secretaryId
  ) {
    let clerk = localStorage.getItem('clerk')
    clerk = clerk ? JSON.parse(clerk) : {}
    let secretaryToken = localStorage.getItem('secretaryToken')
    let secretaryRefreshToken = localStorage.getItem('secretaryRefreshToken')
    // debugger
    if (clerk?.id && clerk.role === 'clerk') {
      window.open(
        `${
          process.env.REACT_APP_SECRETARY_URL
        }/token?token=${secretaryToken}&refresh=${secretaryRefreshToken}&showId=${
          clerk?.show_id || ''
        }`,
        '_self'
      )
    } else {
      window.open(`${process.env.REACT_APP_SECRETARY_URL}`, '_self')
    }
  } else {
    // localStorage.clear();
    localStorage.removeItem('secretaryToken')
    localStorage.removeItem('secretary')
    localStorage.removeItem('secretaryRefreshToken')
    localStorage.removeItem('clerk')
  }
  const AuLayout = getAuthLayout()
  return (
    <React.Fragment>
      {/* <Router> */}
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={AuLayout}
            component={route.component}
            key={idx}
            exact
            isAuthProtected={true}
            isAuth={userData.isAuth}
          />
        ))}

        {/* Easy Fieds Route */}
        {easyfidesRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={EasyFiedsLayout}
            component={route.component}
            key={idx}
            exact
            isAuthProtected={true}
            isAuth={userData.isAuth}
          />
        ))}

        {/* UnAuth Route */}
        {UnAuthRoutes.map((route, idx) => (
          <Authmiddleware
            exact
            path={route.path}
            layout={Layout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}
      </Switch>
      {/* </Router> */}
      {/* <Modal isOpen={showModal} className='modal-dialog fullModal modal-lg  overflow-hidden' fullscreen={true}  >
        <ModalBody style={{ height: '90vh', opacity: "0.7" }} className='bg-white d-flex flex-column align-items-center justify-content-center' >

          <h1>Temporarily Down for Maintenance</h1>

          <h4>
            We are performing scheduled maintenance. We should be back online shortly
          </h4>



        </ModalBody>

      </Modal> */}
      <ToastContainer />
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default withOneTabEnforcer({
  appName: 'Easy-to-show',
  localStorageResetInterval: 10 * 1000,
  localStorageTimeout: 10 * 1000,
  OnlyOneTabComponent: DifferentWarningComponent,
})(App)
