import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, FormGroup, Input, Label } from 'reactstrap'
import { get, patch } from '../../../helpers/api_helper'
import { toast } from 'react-toastify'
import * as validation from '../../../helpers/validation'
import * as Util from '../../../helpers/utils'
import { easyFidesProfile } from '../../../store/actions'
const MyProfile = () => {
  const dispatch = useDispatch()
  const { easyFiedsUser } = useSelector((state) => state.EasyfidesProfile)
  //

  const [inputs, setInputs] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    address1: '',
    address2: '',
    country: '',
    state: '',
    city: '',
    zipcode: '',
    arbaNo: '',
    alias_name_easyfied: '',
    m_animal_listing: 1,
    m_service_judge: 1,
    m_selling_equipments: 1,
    share_details_easyfied: 1,
  })
  const [country, setCountry] = useState([])

  const [allState, setAllState] = useState([])
  const [error, setError] = useState({})
  //
  useEffect(() => {
    if (_.size(easyFiedsUser)) {
      let address = _.first(easyFiedsUser?.address)
      let phone = `${easyFiedsUser?.phone?.substring(
        0,
        3
      )}-${easyFiedsUser?.phone?.substring(
        3,
        6
      )}-${easyFiedsUser?.phone?.substring(6, easyFiedsUser.phone.length)}`
      setInputs({
        firstName: easyFiedsUser.first_name,
        lastName: easyFiedsUser.last_name,
        alias_name_easyfied: easyFiedsUser.alias_name_easyfied
          ? easyFiedsUser?.alias_name_easyfied
          : `${easyFiedsUser.last_name} ${easyFiedsUser.first_name}`,
        email: easyFiedsUser.email,
        id: easyFiedsUser.id,
        phone: phone,
        address1: address?.address_1,
        address2: address?.address_2,
        country: address?.country_id,
        state: address?.state_id,
        city: address?.city,
        zipcode: address?.zipcode,
        m_animal_listing: easyFiedsUser?.m_animal_listing,
        m_service_judge: easyFiedsUser?.m_service_judge,
        m_selling_equipments: easyFiedsUser?.m_selling_equipments,
        share_details_easyfied: easyFiedsUser?.share_details_easyfied,
      })
      if (address?.country_id) {
        get(
          `/api/v1/address/state/all?country_id=${address?.country_id}&_order=asc&_sort=state_code`
        )
          .then((data) => {
            setAllState(data.data)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }, [easyFiedsUser])
  useEffect(() => {
    get(`/api/v1/address/country/all`)
      .then((data) => {
        setCountry(data.data)
      })
      .catch((error) => {
        if (error?.response?.data) toast.error(error?.response?.data)
      })
  }, [])

  const onSubmit = () => {
    let validationFlag = false
    let err = {}
    if (!inputs.alias_name_easyfied.trim()) {
      validationFlag = true
      err.name = `Name ${validation.REQUIRED}`
    }
    if (inputs.alias_name_easyfied.trim()) {
      if (!Util.ONLY_CHAR_REGEX.test(inputs.alias_name_easyfied.trim())) {
        validationFlag = true
        err.name = `Name ${validation.ONLY_CHARACTER_AND_HYPHEN}`
      } else if (inputs.alias_name_easyfied.trim().length >= 50) {
        validationFlag = true
        err.name = `Name ${validation.MAX_CHAR}`
      }
    }
    setError(err)
    if (!validationFlag) {
      document.getElementById('submit-btn').setAttribute('disabled', true)
      let obj = {
        alias_name_easyfied: inputs.alias_name_easyfied.trim(),
        m_animal_listing: inputs?.m_animal_listing || 0,
        m_service_judge: inputs?.m_service_judge || 0,
        m_selling_equipments: inputs?.m_selling_equipments || 0,
        share_details_easyfied: inputs?.share_details_easyfied || 0,
      }

      patch(`/api/v1/profile/easyfied`, obj)
        .then((res) => {
          document.getElementById('submit-btn').removeAttribute('disabled')
          if (res) {
            dispatch(easyFidesProfile())
            toast.success(res)
          }
        })
        .catch((err) => {
          if (err?.response?.data) {
            toast.error(err?.response?.data)
          }
        })
    }
  }
  return (
    <div className='profile-content-area my-6 card card-body mb-10 new-card'>
      {/* <form> */}
      <div class='col-md-12 col-lg-12 col-xs-12 mb-3'>
            <marquee className='text-red' behavior='scroll' scrollamount='6' direction='left'>
              <h5 className='text-danger text-red fs-new'>Welcome to <strong> <em>Easyfieds,</em></strong> this is currently a free service.  
              View the How 2's for more information on how to use  <strong> <em>Easyfieds.</em></strong></h5>
            </marquee>
          </div>
      <div className='pb-6 easyfied-form'>
        <h4 className='mb-3'>General Information</h4>
        <div className='row mb-4'>
          <div className='col-md-12'>
            <div className='form-group mb-3'>
              <Label className='form-label'>Name / Alias</Label>
              <Input
                type='text'
                name='alias_name_easyfied'
                placeholder='Name'
                className='form-control'
                onChange={(e) =>
                  setInputs((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }))
                }
                value={`${inputs?.alias_name_easyfied}`}
              />
              {error?.name ? (
                <div className='text-danger'>{error?.name}</div>
              ) : null}
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group mb-3'>
              <Label className='form-label'>Address 1</Label>
              <Input
                type='text'
                placeholder='Address 1'
                className='form-control'
                disabled
                //   value='315 park st'
                value={inputs?.address1}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group mb-3'>
              <Label className='form-label'>Address 2</Label>
              <Input
                type='text'
                placeholder='Address 2'
                className='form-control'
                disabled
                //   value='315 park st'
                value={inputs?.address2}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group mb-3'>
              <Label className='form-label'>Email</Label>
              <Input
                type='email'
                placeholder='Email'
                className='form-control'
                disabled
                //   value='pshinde1@starlab.co.in'
                value={`${inputs?.email}`}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group mb-3'>
              <Label className='form-label'>Phone</Label>
              <Input
                type='text'
                placeholder='123-456-7890'
                className='form-control'
                disabled
                //   value='123-456-7890'
                value={`${inputs?.phone}`}
              />
            </div>
          </div>

          <div className='col-md-6'>
            <div className='form-group mb-3'>
              <Label className='form-label'>Country</Label>
              <select
                type='text'
                name='country'
                id='country'
                className='form-control form-select'
                value={inputs.country}
                disabled
              >
                <option value=''>Select</option>
                {Array.isArray(country) && country.length
                  ? country.map((_country, index) => {
                      return (
                        <option value={_country?.country_id} key={index}>
                          {_country?.country_name || ''}
                        </option>
                      )
                    })
                  : null}
              </select>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='form-group mb-3'>
              <Label className='form-label'>State</Label>
              <select
                type='text'
                name='state'
                id='state'
                className='form-control form-select'
                value={inputs?.state}
                disabled
              >
                <option value=''>Select</option>
                {allState.map((state) => {
                  return (
                    <option value={state.state_id}>{state.state_code}</option>
                  )
                })}
              </select>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='form-group mb-3'>
              <Label className='form-label'>City</Label>
              <Input
                type='text'
                name='city'
                placeholder='Sheffield'
                className='form-control'
                disabled
                value={inputs?.city}
              />
            </div>
          </div>

          <div className='col-md-6'>
            <div className='form-group mb-3'>
              <Label className='form-label'>Zipcode</Label>
              <Input
                type='text'
                name='zipcode'
                placeholder='Zipcode'
                className='form-control'
                disabled
                value={inputs?.zipcode}
              />
            </div>
          </div>
        </div>
        <Col sm={12}>
          <Label>Do you wish to share your contact details online?</Label>
          <FormGroup
            onChange={() =>
              setInputs((prev) => ({
                ...prev,
                share_details_easyfied:
                  prev?.share_details_easyfied === 0 ? 1 : 0,
              }))
            }
          >
            <Input
              type='radio'
              value={1}
              name='share_details_easyfied'
              id='Yes'
              checked={inputs?.share_details_easyfied === 1 ? true : false}
            />{' '}
            <Label check htmlFor='Yes'>
              Yes
            </Label>
            <Input
              type='radio'
              value={0}
              name='share_details_easyfied'
              id='No'
              checked={inputs?.share_details_easyfied === 0 ? true : false}
            />{' '}
            <Label check htmlFor='No'>
              No
            </Label>
          </FormGroup>
        </Col>

        <p className='contact_note text-dark mt-3 h6'>
          {!inputs?.share_details_easyfied
            ? 'None of your details will be displayed to the viewers, you will receive messages sent by interested viewers, to your listed email address'
            : 'Only your name/alias and email address will be displayed to viewers'}
        </p>
        <label className='mt-2 text-dark'>
          {' '}
          Check the category that apply?
        </label>
        <div className='row'>
          <div className='col-md-12'>
            {Array.isArray(List)
              ? List.map((list) => {
                  return (
                    <div className='form-check mb-2'>
                      <Input
                        className='form-check-input'
                        type='checkbox'
                        id={list?.name}
                        name={list?.abbr}
                        checked={inputs[list?.abbr] ? true : false}
                        onChange={(e) =>
                          setInputs((prev) => ({
                            ...prev,
                            [e.target.name]: !prev[list?.abbr] ? 1 : 0,
                          }))
                        }
                      />
                      <Label className='form-check-label' htmlFor={list?.name}>
                        {list?.name}
                      </Label>
                    </div>
                  )
                })
              : null}
          </div>
          <div className='p-20px-t mt-6'>
            <Button
              type='submit'
              name='btnSubmit'
              className='w-lg waves-effect waves-light btn btn-success'
              onClick={onSubmit}
              title='Submit'
              id='submit-btn'
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </div>
      {/* </form> */}
    </div>
  )
}

export default MyProfile

//
const List = [
  {
    name: 'My Animal Listings',
    abbr: 'm_animal_listing',
  },
  {
    name: 'Service as a Judge or Registrar',
    abbr: 'm_service_judge',
  },
  {
    name: 'Selling new or used equipment (Non Vendor)',
    abbr: 'm_selling_equipments',
  },
]
