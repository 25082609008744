/* eslint-disable jsx-a11y/no-distracting-elements */
import 'flatpickr/dist/themes/material_blue.css'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getDbDate } from '../../helpers/date_helper'
import { getAllEvent } from '../../store/event/actions'
import { setCurrentPage } from '../../store/pagination/actions'
import { EventListItem } from './Components/EventListItem'
import { EventSearch } from './Components/EventSearch'
import { get } from '../../helpers/api_helper'
import _ from 'lodash'
import ReactPaginate from 'react-paginate'
const Events = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [tickers, setTickers] = useState('')
  useEffect(() => {
    dispatch(setCurrentPage(1))
    if (localStorage.getItem('accessToken')) {
      history.push('/auth-events')
    }
  }, [])
  useEffect(() => {
    // dispatch(getAllEntry());
    // dispatch(getAllExhibitorList());
    // dispatch(getAllCategory());
  }, [])

  const { allEvents, loading, totalPages } = useSelector(({ event }) => event)
  
  const { currentPage, perPage } = useSelector(({ pagination }) => pagination)

  const handleNext = (val) => {
    var element = document.querySelectorAll('.toggle-example')
    element?.forEach((elem) => (elem.style.display = 'none'))
    var element2 = document.querySelectorAll('.runningTotal')
    element2?.forEach((elem) => (elem.style.display = 'none'))

    var element1 = document.querySelectorAll('.fa-chevron-circle-up')
    element1?.forEach((elem1) => {
      elem1?.classList.add('fa-chevron-circle-down')
      elem1?.classList.remove('fa-chevron-circle-up')
    })

    dispatch(setCurrentPage(val))
    //alert(val)
    let data = {}
    if (inputs.search !== '') {
      data.search_text = inputs.search
    }
    if (inputs.startDate) {
      data.start_date = getDbDate(inputs.startDate)
    }
    if (inputs.endDate) {
      data.end_date = getDbDate(inputs.endDate)
    }
    if (inputs.active1 !== 'both') {
      data.event_type = inputs.active1
    }
    if (inputs.active2 !== 'both') {
      data.show_type = inputs.active2
    }
    if (inputs.active3 !== 'both') {
      data.show_category = inputs.active3
    }
    if (inputs.active4 !== 'all') {
      data.breed_category_id = inputs.active4
    }

    dispatch(getAllEvent(val, perPage, data))
  }

  const [inputs, setInputs] = useState({
    active1: 'both',
    active2: 'both',
    active3: 'both',
    active4: 'all',
  })

  useEffect(() => {
    dispatch(getAllEvent(1, perPage, {}))
    get(`/api/v1/notes/admin/all`, {
      params: {
        _sort: 'created_on',
        _order: 'DESC',
        role: 'user',
        publish: 1,
        _limit: 5,
      },
    }).then((res) => {
      if (Array.isArray(res?.data) && res?.data.length > 0) {
        // let tickers = _.map(_.get(res?.data, 'notes'))
        let tickers = _.get(_.first(res?.data), 'notes')
        // let tickers = _.join(
        //   _.map(res?.data, (item) => _.get(item, 'notes', '')),
        //   ' ,    '
        // )
        setTickers(tickers)
      }
    })
  }, [])

  return (
    <React.Fragment>
      <section className='section bg-gray-101 pages event-list mt-12'>
        <div class='auto-fit container-fluid'>
          <div class='row mb-6'>
            <div class='col-md-12 col-lg-12 col-xs-12'>
              <marquee behavior='scroll' scrollamount='5' direction='left'>
                <h4 className='text-danger'>{tickers}</h4>
              </marquee>
            </div>
            <div class='col-md-12 col-lg-12 col-xs-12 text-center'>
              <h2 className='top-title'>
                <span>Event List</span>
              </h2>
            </div>
          </div>
          <EventSearch setInputs={setInputs} inputs={inputs} />

          {allEvents?.map((event, index) => {
            return <EventListItem key={index} event={event} />
          })}

          {loading && (
            <div className='text-center'>
              <p style={{ fontSize: 24 }}>Loading...</p>
            </div>
          )}

          {!allEvents && (
            <div
              style={{
                textAlign: 'center',
                fontSize: 'xx-large',
                minHeight: '80vh',
              }}
            >
              {' '}
              No Events Found{' '}
            </div>
          )}
          {totalPages > 0 ? (
            <ReactPaginate
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              nextLabel='>'
              pageCount={totalPages}
              pageRangeDisplayed={4}
              marginPagesDisplayed={1}
              previousLabel='<'
              renderOnZeroPageCount={null}
              containerClassName='pagination justify-content-center'
              pageLinkClassName='page-link page-link'
              pageClassName='page-item'
              activeClassName='active'
              nextLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              onPageChange={(nextPage) => {
                const { selected } = nextPage
                const finalPage = (Number(selected) || 0) + 1
                handleNext(finalPage)
              }}
              forcePage={currentPage - 1}
            />
          ) : null}
          {/*           
            <Pagination
              paginationClick={(nextPage) => {
                const { selected } = nextPage
                const finalPage = (Number(selected) || 0) + 1
                handleNext(finalPage)
              }}
            /> */}

          {/* {totalPages > 0 && (
            <div class='col-12 pt-3'>
              <></>
              <ul class='pagination justify-content-center'>
                <li
                  class='page-item pe-auto1'
                  disabled={!Boolean(currentPage - 1)}
                  onClick={() => {
                    if (currentPage != 1) {
                      handleNext(currentPage - 1)
                    }
                  }}
                >
                  <a
                    class='page-link'
                    style={{
                      color: `${currentPage == 1 ? '#c3c3c3' : ''}`,
                      cursor: `${currentPage == 1 ? 'not-allowed' : ''}`,
                    }}
                  >
                    <i class='fa fa-chevron-left'></i> Previous
                  </a>
                </li>
                {[...Array(totalPages)].map((x, i) => {
                  return (
                    <li
                      class={`page-item ${
                        currentPage == i + 1 ? 'active' : ''
                      } pe-auto1`}
                      onClick={() => {
                        handleNext(i + 1)
                      }}
                    >
                      <a class='page-link'>
                        {i + 1} <span class='sr-only'></span>
                      </a>
                    </li>
                  )
                })}
                <li
                  class='page-item pe-auto1'
                  onClick={() => {
                    if (currentPage != totalPages) {
                      handleNext(currentPage + 1)
                    }
                  }}
                  desabled={currentPage == totalPages}
                >
                  <a
                    class='page-link '
                    style={{
                      color: `${currentPage == totalPages ? '#c3c3c3' : ''}`,
                      cursor: `${
                        currentPage == totalPages ? 'not-allowed' : ''
                      }`,
                    }}
                  >
                    Next <i class='fa fa-chevron-right'></i>
                  </a>
                </li>
              </ul>
            </div>
          )} */}
        </div>
      </section>
    </React.Fragment>
  )
}
export default Events
