import React, { useState, useEffect } from "react";
export const Policy = () => {
  return (
    <div className="col-lg-12">
      <div className="card shadow-only-hover mt-8 regi-box">
        <div className="card-body">
          <h3>Website Terms and Conditions</h3>
          <p>
            <strong>
              IMPORTANT: The headings and summaries provided herein are for
              convenience only, and do not in any way detract or add to the
              meaning or intent of the Terms. They are not a substitute for
              careful consideration of these Terms. Please read these Terms
              carefully.{" "}
            </strong>
          </p>
          <p>
            <strong>Acceptance of the Terms of Use </strong>
          </p>
          <p>
            These terms and conditions are entered into by and between You and
            Advanced Rabbit Solutions, LLC, DBA Easy2Show. (the “Company”). The
            following terms and conditions, together with any documents they
            expressly incorporate by reference (collectively, the “Terms”),
            govern your access to and use of Easy2Show including any content,
            functionality, and services offered on or through (the “Website”),
            whether as a guest or a registered user.
          </p>
          <p>
            Please read the Terms carefully before you start to use the Website.
            By using the Website or by clicking to accept or agree to the Terms
            when this option is made available to you, you accept and agree to
            be bound and abide by these Terms and comply with all applicable
            laws and regulations. If you do not want to agree to these Terms,
            you must not access or use the Website.
          </p>
          <p>
            This Website is offered and available to users who are thirteen (13)
            years of age or older and reside in the United States or any of its
            territories or possessions. By using this Website, you represent and
            warrant that you are of legal age to form a binding contract with
            the Company and meet all of the foregoing eligibility requirements.
            If you do not meet all of these requirements, you must not access or
            use the Website.
          </p>
          <p>
            By agreeing to the these terms and conditions, you authorize us to
            put you onto our mailing list for future correspondence including
            but not limited to; updates, specials, promotions, etc.
          </p>
          <p>
            <strong>Changes to the Terms</strong>
          </p>
          <p>
            The Company may revise and update these Terms from time to time in
            our sole discretion. All changes and modifications shall immediately
            become effective upon posting and apply to all access to and use of
            the Website thereafter. Your continued use of the Website following
            the posting of revised Terms means that you accept and agree to the
            changes. You are expected to check this page from time to time so
            you are aware of any changes, as they are binding on you.
          </p>
          <p>
            <strong>Accessing the Website and User Account Security</strong>
          </p>
          <p>
            {" "}
            We reserve the right to withdraw or amend this Website, and any
            service or material we provide on the Website, in our sole
            discretion without notice. We will not be liable if for any reason
            all or any part of the Website is unavailable at any time or for any
            period. From time to time, we may restrict access to some parts of
            the Website, or the entire Website, to users, including registered
            users.
          </p>
          <p> You are responsible for both:</p>
          <ul className="list-type-04 mb-4">
            <li>
              Making all arrangements necessary for you to have access to the
              Website.
            </li>
            <li>
              {" "}
              Ensuring that all persons who access the Website through your
              internet connection are aware of these Terms and comply with them.
            </li>
          </ul>
          <p>
            To access the Website or some of the resources it offers, you may be
            asked to provide certain registration details or other information.
            It is a condition of your use of the Website that all the
            information you provide on the Website is correct, current, and
            complete. If you choose, or are provided with, a user name,
            password, or any other piece of information as part of our security
            procedures, you must treat such information as confidential, and you
            must not disclose it to any other person or entity. You also
            acknowledge that your account is personal to you and agree not to
            provide any other person with access to this Website or portions of
            it using your user name, password, or other security information.
            You agree to notify us immediately of any unauthorized access to or
            use of your user name or password or any other breach of security.
            You also agree to ensure that you exit from your account at the end
            of each session. You should use particular caution when accessing
            your account from a public or shared computer so that others are not
            able to view or record your password or other personal information.
          </p>
          <p>
            We have the right to disable any user name, password, or other
            identifier, whether chosen by you or provided by us, at any time in
            our sole discretion for any or no reason, including if, in our
            opinion, you have violated any provision of these Terms.
          </p>
          <p>
            <strong>Intellectual Property Rights</strong>
          </p>
          <p>
            The Website and its entire contents, features, and functionality
            (including but not limited to all information, software, text,
            displays, images, video, and audio, and the design, selection, and
            arrangement thereof) are owned by the Company, its licensors, or
            other providers of such material and are protected by United States
            and international copyright, trademark, patent, trade secret, and
            other intellectual property or proprietary rights laws.
          </p>
          <p>
            You must not reproduce, distribute, modify, create derivative works
            of, publicly display, publicly perform, republish, download, store,
            or transmit any of the material on our Website, except as follows:
          </p>
          <ul className="list-type-04 mb-4">
            <li>
              Your computer may temporarily store copies of such materials in
              RAM incidental to your accessing and viewing those materials.
            </li>
            <li>
              You may store files that are automatically cached by your Web
              browser for display enhancement purposes.
            </li>
            <li>
              You may print or download one copy of a reasonable number of pages
              of the Website for your own personal use and not for further
              reproduction, publication, or distribution.
            </li>
            <li>
              If we provide social media features with certain content, you may
              take such actions as are enabled by such features.
            </li>
          </ul>
          <p>You must not:</p>
          <ul className="list-type-04 mb-4">
            <li>Modify copies of any materials from this site.</li>
            <li>
              Use any illustrations, photographs, video or audio sequences, or
              any graphics separately from the accompanying text.{" "}
            </li>
            <li>
              Delete or alter any copyright, trademark, or other proprietary
              rights notices from copies of materials from this Website.{" "}
            </li>
          </ul>
          <p>
            If you print, copy, modify, download, or otherwise use or provide
            any other person with access to any part of the Website in breach of
            these Terms, your right to use the Website will stop immediately and
            you must, at our option, return or destroy any copies of the
            materials you have made. No right, title, or interest in or to the
            Website or any content on the Website is transferred to you, and all
            rights not expressly granted are reserved by the Company. Any use of
            the Website not expressly permitted by these Terms is a breach of
            the same and may violate copyright, trademark, and other laws.
          </p>
          <p>
            <strong>Trademarks</strong>
          </p>
          <p>
            The Company name, the Company logo, and all related names, logos,
            product and service names, designs, and slogans are trademarks of
            the Company or its affiliates or licensors. You must not use such
            marks without the prior written permission of the Company. All other
            names, logos, product and service names, designs, and slogans on
            this Website are the trademarks of their respective owners.
          </p>
          <p>
            <strong>Prohibited Uses</strong>
          </p>
          <p>
            You may use the Website for lawful purposes and in accordance with
            these Terms. You agree not to use the Website:
          </p>
          <ul className="list-type-04 mb-4">
            <li>
              In any way that violates any applicable federal, state, local, or
              international law or regulation (including, without limitation,
              any laws regarding the export of data or software to and from the
              US or other countries).
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or
              promotional material without our prior written consent, including
              any “junk mail,” “chain letter,” “spam,” or any other similar
              solicitation.{" "}
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone's
              use or enjoyment of the Website, or which, as determined by us,
              may harm the Company or users of the Website, or expose them to
              liability.{" "}
            </li>
          </ul>
          <p>Additionally, you agree not to:</p>
          <ul className="list-type-04 mb-4">
            <li>
              Use the Website in any manner that could disable, overburden,
              damage, or impair the site or interfere with any other party’s use
              of the Website, including their ability to engage in real time
              activities through the Website.
            </li>
            <li>
              Use any robot, spider, or other automatic device, process, or
              means to access the Website for any purpose, including monitoring
              or copying any of the material on the Website.{" "}
            </li>
            <li>
              Use any manual process to monitor or copy any of the material on
              the Website, or for any other purpose not expressly authorized in
              these Terms, without our prior written consent.{" "}
            </li>
            <li>
              Use any device, software, or routine that interferes with the
              proper working order of the Website.{" "}
            </li>
            <li>
              Introduce any viruses, Trojan horses, worms, logic bombs, or other
              material that is malicious or technologically harmful.{" "}
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage, or
              disrupt any parts of the Website, the server on which the Website
              is stored, or any server, computer, or database connected to the
              Website.
            </li>
            <li>
              Attack the Website via a denial-of-service attack or a distributed
              denial-of-service attack.
            </li>
            <li>
              Otherwise attempt to interfere with the proper working order of
              the Website.
            </li>
          </ul>
          <p>
            <strong>Reliance on Information Posted</strong>
          </p>
          <p>
            The information presented on or through the Website is made
            available solely for general information purposes. We do not warrant
            the accuracy, completeness, or usefulness of this information. Any
            reliance you place on such information is strictly at your own risk.
            We disclaim all liability and responsibility arising from any
            reliance placed on such materials by you or any other visitor to the
            Website, or by anyone who may be informed of any of its contents.
          </p>
          <p>
            This Website may include content provided by third parties,
            including materials provided by other users, bloggers, and
            third-party licensors, syndicators, aggregators, and/or reporting
            services. All statements and/or opinions expressed in these
            materials, and all articles and responses to questions and other
            content, other than the content provided by the Company, are solely
            the opinions and the responsibility of the person or entity
            providing those materials. These materials do not necessarily
            reflect the opinion of the Company. We are not responsible, or
            liable to you or any third party, for the content or accuracy of any
            materials provided by any third parties.
          </p>
          <p>
            <strong>Changes to the Website</strong>
          </p>
          <p>
            We may update the content on this Website from time to time, but its
            content is not necessarily complete or up-to-date. Any of the
            material on the Website may be out of date at any given time, and we
            are under no obligation to update such material.
          </p>
          <p>
            <strong>Information and You and Your Visits to the Website</strong>
          </p>
          <p>
            All information we collect on this Website is subject to our Privacy
            Policy. By using the Website, you consent to all actions taken by us
            with respect to your information in compliance with the Privacy
            Policy.
          </p>
          <p>
            <strong>Online Purchases and Other Terms and Conditions</strong>
          </p>
          <p>
            All purchases through the Website or other transactions for the sale
            of goods formed through the Website, or resulting from visits made
            by you, are governed by the Terms. When completing such purchases,
            these payments are final and non-refundable, unless otherwise
            determined by the Company. The Company, in its sole discretion, may
            offer credit or refunds on a case-by-case basis including, by way of
            example, in the event of an error with your order or in the amounts
            you were charged.
          </p>
          <p>
            The Company will charge, and you authorize the Company to charge,
            the payment method you specify at the time of purchase. If you pay
            any amounts with a credit card, the Company may seek
            pre-authorization of your credit card account prior to your purchase
            to verify that the credit card is valid and has credit available for
            your intended purchase.
          </p>
          <p>
            The Company reserves the right to establish, remove, and/or revise
            prices, fees, and/or surcharges for any or all goods obtained
            through the use of the Website at any time. The Company may also, in
            its sole discretion, make promotional offers with different features
            and different rates to any or all of our customers. Unless made to
            you, these promotional offers will have no bearing on your
            obligation to pay the amounts charged.
          </p>
          <p>
            <strong>Linking to Website and Social Media Features</strong>
          </p>
          <p>
            You may link to our homepage, provided you do so in a way that is
            fair and legal and does not damage our reputation or take advantage
            of it, but you must not establish a link in such a way as to suggest
            any form of association, approval, or endorsement on our part
            without our express, written consent.
          </p>
          <p>
            This Website may provide certain social media features that enable
            you to:
          </p>
          <ul className="list-type-04 mb-4">
            <li>
              Link from your own or certain third-party websites to certain
              content on this Website.
            </li>
            <li>
              Send emails or other communications with certain content, or links
              to certain content, on this Website.{" "}
            </li>
            <li>
              Cause limited portions of content on this Website to be displayed
              or appear to be displayed on your own or certain third-party
              websites.{" "}
            </li>
          </ul>
          <p>
            You may use these features solely as they are provided by the
            Company, solely with respect to the content they are displayed with,
            and otherwise in accordance with any additional terms and conditions
            we provide with respect to such features. Subject to the foregoing,
            you must not:
          </p>
          <ul className="list-type-04 mb-4">
            <li>Establish a link from any website that is not owned by you.</li>
            <li>
              Cause the Website or portions of it to be displayed on, or appear
              to be displayed by, any other site, for example, framing, deep
              linking, or in-line linking.{" "}
            </li>
            <li>Link to any part of the Website other than the homepage. </li>
            <li>
              {" "}
              Otherwise take any action with respect to the materials on this
              Website that is inconsistent with any other provision of these
              Terms.{" "}
            </li>
          </ul>
          <p>
            You agree to cooperate with us in causing any unauthorized framing
            or linking immediately to stop. We reserve the right to withdraw
            linking permission without notice.
          </p>
          <p>
            {" "}
            We may disable all or any social media features and any links at any
            time without notice in our sole discretion.
          </p>
          <p>
            <strong>Third-Party Links from Website</strong>
          </p>
          <p>
            If the Website contains links to other sites and resources provided
            by third parties, these links are provided for your convenience
            only. This includes links contained in advertisements, including
            banner advertisements and sponsored links. We have no control over
            the contents of those sites or resources, and accept no
            responsibility for them or for any loss or damage that may arise
            from your use of them. If you decide to access any of the
            third-party websites linked to this Website, you do so entirely at
            your own risk and subject to the terms and conditions of use for
            such websites.
          </p>
          <p>
            <strong>Geographic Restrictions</strong>
          </p>
          <p>
            The owner of the Website is based in the State of Iowa in the United
            States. We provide this Website for use only by persons located in
            the United States. We make no claims that the Website or any of its
            content is accessible or appropriate outside of the United States.
            Access to the Website may not be legal by certain persons or in
            certain countries. If you access the Website from outside the United
            States, you do so on your own initiative and are responsible for
            compliance with local laws.
          </p>
          <p>
            <strong>Disclaimer of Warranties</strong>
          </p>
          <p>
            You understand that we cannot and do not guarantee or warrant that
            files available for downloading from the internet or the Website
            will be free of viruses or other destructive code. You are
            responsible for implementing sufficient procedures and checkpoints
            to satisfy your particular requirements for anti-virus protection
            and accuracy of data input and output, and for maintaining a means
            external to our site for any reconstruction of any lost data. WE
            WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
            DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL
            MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS,
            DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE
            OR ANY GOODS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF
            ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
          </p>
          <p>
            YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY GOODS OBTAINED THROUGH
            THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY
            GOODS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN “AS IS” AND
            “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
            EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED
            WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT
            TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
            AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER
            THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR
            WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY GOODS OBTAINED
            THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
            UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE
            SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS, OR THAT THE WEBSITE OR ANY GOODS OBTAINED THROUGH THE
            WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </p>
          <p>
            <strong>
              THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
              EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT
              LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
              AND FITNESS FOR PARTICULAR PURPOSE.
            </strong>
          </p>
          <p>
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
            OR LIMITED UNDER APPLICABLE LAW.
          </p>
          <p>
            <strong>Limitation on Liability</strong>
          </p>
          <p>
            IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS,
            SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE
            LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT
            OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE,
            ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER
            WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
            CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO,
            PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
            REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
            LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY
            TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN
            IF FORESEEABLE.
          </p>
          <p>
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED
            OR LIMITED UNDER APPLICABLE LAW.
          </p>
          <p>
            <strong>Indemnification</strong>
          </p>
          <p>
            You agree to defend, indemnify, and hold harmless the Company, its
            affiliates, licensors, and service providers, and its and their
            respective officers, directors, employees, contractors, agents,
            licensors, suppliers, successors, and assigns from and against any
            claims, liabilities, damages, judgments, awards, losses, costs,
            expenses, or fees (including reasonable attorneys’ fees) arising out
            of or relating to your violation of these Terms or your use of the
            Website, including, but not limited to, any use of the Website's
            content, services, and products other than as expressly authorized
            in these Terms or your use of any information obtained from the
            Website.
          </p>
          <p>
            <strong>Governing Law</strong>
          </p>
          <p>
            All matters relating to the Website and these Terms, and any dispute
            or claim arising therefrom or related thereto (in each case,
            including non-contractual disputes or claims), shall be governed by
            and construed in accordance with the internal laws of the State of
            Wisconsin without giving effect to any choice or conflict of law
            provision or rule (whether of the State of Wisconsin or any other
            jurisdiction).
          </p>
          <p>
            <strong> Arbitration</strong>
          </p>
          <p>
            <strong>
              BY AGREEING TO THESE TERMS, YOU AGREE THAT YOU ARE REQUIRED TO
              RESOLVE ANY CLAIM THAT YOU MAY HAVE AGAINST THE COMPANY ON AN
              INDIVIDUAL BASIS IN BINDING AND CONFIDENTIAL ARBITRATION, AS SET
              FORTH IN THIS ARBITRATION AGREEMENT.
            </strong>
          </p>
          <p>
            Any dispute, claim, or controversy arising out of or relating to
            this Agreement or the breach, termination, enforcement,
            interpretation, or validity thereof, including the determination of
            the scope or applicability of this agreement to arbitrate, shall be
            determined by final, binding, and confidential arbitration in Iowa
            before one arbitrator. The arbitration shall be administered by JAMS
            pursuant to its Comprehensive Arbitration Rules and Procedures.
            Judgment on the Award may be entered in any court having
            jurisdiction. This clause shall not preclude parties from seeking
            provisional remedies in aid of arbitration from a court of
            appropriate jurisdiction.
          </p>
          <p>
            <strong>Limitation on Time to File Claims</strong>
          </p>
          <p>
            ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
            TO THESE TERMS OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR
            AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION
            OR CLAIM IS PERMANENTLY BARRED.
          </p>
          <p>
            <strong>Waiver and Severability</strong>
          </p>
          <p>
            No waiver by the Company of any term or condition set out in these
            Terms shall be deemed a further or continuing waiver of such term or
            condition or a waiver of any other term or condition, and any
            failure of the Company to assert a right or provision under these
            Terms shall not constitute a waiver of such right or provision.
          </p>
          <p>
            If any provision of these Terms is held by a court or other tribunal
            of competent jurisdiction to be invalid, illegal, or unenforceable
            for any reason, such provision shall be eliminated or limited to the
            minimum extent such that the remaining provisions of the Terms will
            continue in full force and effect.
          </p>
          <p>
            <strong>Entire Agreement</strong>
          </p>
          <p>
            These Terms constitute the sole and entire agreement between you and
            the Company regarding the Website and supersede all prior and
            contemporaneous understandings, agreements, representations, and
            warranties, both written and oral, regarding the Website.
          </p>
          <p>
            <strong>Your Comments and Concerns</strong>
          </p>
          <p>This Website is operated by:</p>
          <p>
            All other feedback, comments, requests for technical support, and
            other communications relating to the Website should be directed to:
            www.Easy2Show.com
          </p>
        </div>
      </div>
    </div>
  );
};
