import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get, post } from '../../../../helpers/api_helper';
import CustomizedDialogs from './popup';
import * as url from '../../../../helpers/url_helper';
import { config } from '../../../../store/shoppingCart/saga';
import { useDispatch } from 'react-redux';

export const Loginform = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputElement = useRef(null);
  const [inputs, setInputs] = useState({ role: 'user' });
  const [showPassword, setShowPassword] = useState(false);
  let showPwd = () => {
    setShowPassword(true);
  };
  let hidePwd = () => {
    setShowPassword(false);
  };
  const [errors, setErrors] = useState({
    email: null,
    password: null,
  });
  const [isNotValid, setIsNotValid] = useState(false);
  let [showBtn, setShowBtn] = useState(false);

  const [valiadtionMsg, setValiadtionMsg] = useState('');

  const [showAlert, setShowAlert] = useState('');
  const [msg, setMsg] = useState('');
  const [activeTab, setActiveTab] = useState('user');
  console.log(activeTab);
  const [uniqueString, setUniqueString] = useState(null);

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    // setInputs((values) => ({ ...values, [name]: value }))

    let err = {};
    if (name === 'email') {
      // err.email = !value || !emailRegex.test(value) ? true : null

      if (value.trim()) {
        get(
          `/api/v1/register/is_email_exist?email=${value.trim()}&user_role=${
            inputs.role
          }`
        )
          .then((data) => {
            if (
              data.is_exist === true &&
              data.account_status === 'UNVERIFIED'
            ) {
              // document.getElementById('smg-btn').disabled = true
              setShowAlert(true);
              setShowBtn(true);
              setMsg(
                'Your account is not verified yet. Please click on resend verification link on website. Verify  your account to login.'
              );
            }
            if (data.is_exist === true && data.account_status === null) {
              // document.getElementById('smg-btn').disabled = true
              setUniqueString(data.secretary_verification_code);
              setShowAlert(true);
              setShowBtn(true);
              setMsg(
                'Your account is not verified yet. Please click on resend verification link on website. Verify  your account to login.'
              );
            }
          })
          .catch((error) => {});
      }
    }
    if (name === 'email') {
      setInputs({ ...inputs, [name]: value.replace(/\s/g, '') });

      //  if(event.)
    } else {
      setInputs({ ...inputs, [name]: value });
    }

    // setInputs((values) => ({ ...values, [name]: value }))
  };
  const handleTabChange = (val) => {
    setInputs({});
    // inputElement.current.focus()
    if (val === 'tab1') {
      setActiveTab('user');
      setInputs((values) => ({
        ...values,
        role: 'user',
        email: '',
        password: '',
      }));
      let tab1 = document.getElementById('tab1');
      let tab2 = document.getElementById('tab2');
      document.getElementById('email').focus();
      tab1.classList.add('active');
      tab2.classList.remove('active');
      setErrors({
        email: null,
        password: null,
      });
    } else {
      setActiveTab('secretary');

      setInputs((values) => ({
        ...values,
        role: 'secretary',
        email: '',
        password: '',
      }));
      let tab1 = document.getElementById('tab1');
      let tab2 = document.getElementById('tab2');
      tab1.classList.remove('active');
      document.getElementById('email').focus();
      tab2.classList.add('active');
      setErrors({
        email: null,
        password: null,
      });
    }
  };

  let handleClicked = () => {
    document.getElementById('smg-btn').disabled = true;
    if (activeTab === 'user') {
      post(`/api/v1/user_activation/resend_verify_link`, {
        email: inputs.email,
      })
        .then((response) => {
          setShowBtn(false);

          setMsg(
            'Email verify link has been successfully sent to your registered Email'
          );
          setInputs({});
          document.getElementById('smg-btn').disabled = false;
        })
        .catch((error) => {
          setShowBtn(false);

          console.log(error);
          document.getElementById('smg-btn').disabled = false;
        });
    }

    if (activeTab === 'secretary') {
      const body = JSON.stringify({ unique_string: uniqueString });
      post(url.RESEND_VERIFICATION_EMAIL, body, config)
        .then((res) => {
          if (res) {
            setShowAlert(true);
            setShowBtn(false);
            setMsg(
              'Email verify link has been successfully sent to your registered Email'
            );
            // setInputs({});
            document.getElementById('smg-btn').disabled = false;
            // }
          }
        })
        .catch((err) => {
          setShowBtn(false);
          document.getElementById('smg-btn').disabled = false;
        });
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    let validationFlag = false;
    let err = {};
    if (!inputs.email) {
      validationFlag = true;
      err.email = 'Email field is required';
    } else if (inputs.email && !emailRegex.test(inputs.email)) {
      validationFlag = true;
      err.email = 'Invalid email address.';
    }
    if (!inputs.password) {
      validationFlag = true;
      err.password = true;
    }
    setErrors(err);
    if (!validationFlag) {
      document.getElementById('submit').setAttribute('disabled', true);
      let accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        post(`/api/v1/login`, {
          role: inputs.role,
          email: inputs.email,
          password: inputs.password,
        })
          .then((response) => {
            if (response?.data.role === 'secretary') {
              toast.success('Login Success !');
              localStorage.setItem('secretaryToken', response.accessToken);
              localStorage.setItem(
                'secretaryRefreshToken',
                response.refreshToken
              );

              localStorage.setItem('secretary', JSON.stringify(response.data));
              setTimeout(() => {
                window.open(
                  `${process.env.REACT_APP_SECRETARY_URL}/token?token=${response.accessToken}&refresh=${response.refreshToken}`,
                  '_self'
                );
                // window.location.href =
              }, 1500);
            }

            if (response?.data.role === 'user') {
              toast.success('Login Success !');
              localStorage.setItem('accessToken', response.accessToken);
              localStorage.setItem('refreshToken', response.refreshToken);
              localStorage.setItem('authUser', JSON.stringify(response.data));
              dispatch({
                type: 'LOGIN_SUCCESS',
                payload: {
                  token: response.accessToken,
                  user: response.authUser,
                },
              });
              let event = localStorage.getItem('events');
              event = event ? JSON.parse(event) : null;
              if (event) {
                history.push({ pathname: '/additional_products' });
              } else {
                history.push({ pathname: '/auth-events' });
              }
            }
          })
          .catch((error) => {
            document.getElementById('submit').removeAttribute('disabled');
            if (error.response?.status === 401) {
              if (error.response?.data === 'Invalid credentials.') {
                toast.error(error.response.data, { toastId: 1 });
              } else {
                setShowAlert(true);
                if (
                  error.response?.data ===
                  'Please contact Admin you are currently blocked.'
                ) {
                  setShowBtn(false);
                } else {
                  setShowBtn(true);
                }
                setMsg(error.response.data);
                //toast.error(error.response.data);
              }
            } else if (error.response?.status === 422) {
              toast.error(error.response.data[0].msg);
            } else if (error.response?.status === 500) {
              toast.error(error.response.data);
            } else {
            }
          });
      } else {
        document.getElementById('submit').removeAttribute('disabled');
        toast.error(
          'You already have an active login session in this browser !',
          { toastId: 1 }
        );
      }
    }
  };
  console.log(showBtn);
  return (
    <React.Fragment>
      <div className="view-main mt-10 res-mt-view">
        <section className="bg-cover bg-no-repeat loginform">
          <div className="container-fuild">
            <div className="row align-items-center justify-content-center min-vh-100">

             {/* <div className="col-md-12 col-xl-3 text-center gift-card mb-4 mt-4">
                <h4>Want to win a $50 Amazon Gift Card??</h4>
                <p> January 22 thru February 3. Create a post on <strong>Easyfieds</strong> and you will be entered to win! Use <strong>Easyfieds</strong> to post
                  rabbits, cavies, rabbit/cavy related items for sale or judges/registrars post here so clubs can find you. Don’t have a
                  rabbit, cavy or related item for sale? Use <strong>Easyfieds</strong> like a breeder listing to show potential customers what breeds
                  you do raise and what you might have in the future. Only people with an <strong>Easy2show</strong> account can view <strong>Easyfieds</strong>,
                  so you don’t have to worry about being put in social media “jail” for your sale post. Login into your Easy2show
                  account to view the <strong>Easyfieds</strong> tab. We will email the winner who will need to provide an address for us to mail the
                  Amazon gift card to. A Signature is required for delivery. The winner will be notified by 2-10-24. Commercial
                  vendors are not eligible for gift card.</p>
            </div> */}

              <div className="col-md-12 col-xl-5 text-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/img/user-login.png`}
                  alt="banner-logo"
                  className="logo-max-view"
                />
              </div>
              <div className="col-md-12 col-xl-2"></div>
              <div className="col-md-12 col-xl-5">
                <div className="card">
                  <div className="card-body user-tab">
                    <div className="card">
                      <div className="bg-blue card-body">
                        <ul className="nav nav-tabs">
                          <li
                            className="nav-item nav-res1"
                            onClick={() => {
                              handleTabChange('tab1');
                            }}
                          >
                            <a className={'active nav-link'} id="tab1">
                              <span className="d-none1 d-sm-block1">
                                USER LOGIN
                              </span>
                            </a>
                          </li>
                          <li
                            className="nav-item nav-res2"
                            onClick={() => {
                              handleTabChange('tab2');
                            }}
                          >
                            <a className="nav-link" id="tab2">
                              <span className="d-none d-sm-none1">
                                <i className="far fa-user"></i>
                              </span>
                              <span className="d-none1 d-sm-block1">
                                SECRETARY LOGIN
                              </span>
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content p-3 bg-white pt-4">
                          <div className="tab-pane active">
                            <form>
                              <div className="form-group mb-4">
                                <label className="form-label">Email Id *</label>
                                <input
                                  type="text"
                                  name="email"
                                  placeholder="Email Id"
                                  className="form-control"
                                  id="email"
                                  value={inputs.email}
                                  onChange={(e) => handleChange(e)}
                                  // onKeyDown={handleChange}
                                  style={{ borderColor: 'black' }}
                                  autoFocus
                                />
                                {errors.email && (
                                  <p style={{ color: 'red' }}>
                                    {errors?.email}
                                  </p>
                                )}
                              </div>

                              <div className="form-group mb-4">
                                <label className="form-label">Password *</label>
                                <input
                                  name="password"
                                  placeholder="Password"
                                  className="form-control"
                                  value={inputs.password || ''}
                                  onChange={handleChange}
                                  style={{ borderColor: 'black' }}
                                  type={showPassword ? 'text' : 'password'}
                                />
                                {showPassword && (
                                  <RemoveRedEyeIcon
                                    className="pwd-icon"
                                    onClick={hidePwd}
                                  />
                                )}
                                {!showPassword && (
                                  <VisibilityOffIcon
                                    className="pwd-icon"
                                    onClick={showPwd}
                                  />
                                )}
                                {errors.password && (
                                  <p style={{ color: 'red' }}>
                                    Password field is required
                                  </p>
                                )}
                              </div>

                              <div className="col-md-12 col-xl-12 pt-2 text-center">
                                <button
                                  className="col-xl-8 btn btn-primary w-1001"
                                  onClick={handleLogin}
                                  id="submit"
                                >
                                  SUBMIT
                                </button>
                              </div>
                              <div className="mt-4 text-center text-font">
                                Don’t have an account?{' '}
                                <Link
                                  to="/register"
                                  className="font-weight-bold link"
                                >
                                  SIGN UP
                                </Link>
                              </div>
                              <div className="mt-2 mb-2 text-center text-font">
                                {' '}
                                <Link
                                  to={`/forgot-password?user=${inputs.role}`}
                                  className="font-weight-bold"
                                >
                                  Forgot Password?
                                </Link>
                              </div>
                              {activeTab === 'user'?(
                                <div className="mt-2 mb-2 text-center text-font">
                                <a
                                  href="https://www.easy2show.com/assets/pdf/Exhibitor-How-2.pdf"
                                  target="_blank"
                                  className="font-weight-bold hw-color"
                                >
                                 How 2 User Registration and Login
                                </a>
                              </div>

                              ):(

                                <div className="mt-2 mb-2 text-center text-font">
                                <a
                                  href="https://www.easy2show.com/assets/pdf/How2-Secretary-Registration.pdf"
                                  target="_blank"
                                  className="font-weight-bold hw-color"
                                >
                                How 2 Secretary Registration and Login
                                </a>
                              </div>
                              )}
                            </form>
                          </div>
                          <div className="tab-pane">
                            <form>
                              <div className="form-group mb-4">
                                <label className="form-label">Email Id *</label>
                                <input
                                  type="email"
                                  name="email"
                                  placeholder="Email Id"
                                  value={inputs.email}
                                  className="form-control"
                                  style={{ borderColor: 'black' }}
                                />
                              </div>
                              <div className="form-group mb-4">
                                <label className="form-label">Password *</label>
                                <input
                                  type="password"
                                  name="password"
                                  placeholder="Password"
                                  className="form-control"
                                  value=""
                                  style={{ borderColor: 'black' }}
                                />
                              </div>
                              <div className="col-md-12 col-xl-12 pt-2 text-center">
                                <button
                                  className="col-xl-8 btn btn-primary w-1001"
                                  onClick={handleLogin}
                                >
                                  SUBMIT
                                </button>
                              </div>
                              <div className="mt-4 text-center text-font">
                                Don’t have an account?{' '}
                                <a
                                  className="font-weight-bold"
                                  href="register.html"
                                >
                                  SIGN UP
                                </a>
                              </div>
                              <div className="mt-2 mb-2 text-center text-font">
                                {' '}
                                <a
                                  className="font-weight-bold"
                                  href="forget_password.html"
                                >
                                  Forgot Password?
                                </a>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CustomizedDialogs
          setShowAlert={setShowAlert}
          msg={msg}
          showAlert={showAlert}
          setMsg={setMsg}
          setShowBtn={setShowBtn}
          showBtn={showBtn}
          handleClicked={handleClicked}
        />
      </div>
    </React.Fragment>
  );
};
