import React from 'react'
import { useLocation } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'

const Footer = () => {
  const location = useLocation()

  return (
    <React.Fragment>
      {location.pathname !== '/clerk_login' && (
        <footer className="footer-page">
          <div>
            <div className="row">
              <div className="col-md-12">
                <div className="text-center d-sm-block">
                  © {new Date().getFullYear()} Easy2Show. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </React.Fragment>
  )
}

export default Footer
