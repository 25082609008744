import classnames from 'classnames'
import _, { isArray } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { components } from 'react-select'
import makeAnimated from 'react-select/animated'
import { Col, Row } from 'reactstrap'
import Select from '../../../components/Common/Select'
import { get, post } from '../../../helpers/api_helper'
import { getDate } from '../../../helpers/date_helper'
import { Tooltip } from 'react-tippy'
import { UrlEncodeHook } from '../../../hooks'

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type='checkbox'
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
)

const animatedComponents = makeAnimated()

function IsAlphaNumeric(e) {
  var val = e.target.value
  if (!val.match(/^[a-zA-Z0-9,*]+$/)) {
    e.preventDefault()
    return false
  } else {
    return true
  }
}

const ParticipateEasyEntryList = ({
  index,
  list,
  showData,
  showList,
  exhibitorList,
  handleRemove,
  handleChange,
  handleEdit,
  handleSave,
  validationList,
  hasFurData,
  breakSection,
}) => {
  // console.log(`🚀 -> file: list.js -> line 59 -> list`, list)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  let event_id = query.get('event_id')
  const { urlDecode } = UrlEncodeHook()
  event_id = urlDecode(event_id)
  const [listShow, setListShow] = useState([])

  const [breeds, setBreeds] = useState([])
  const [groups, setGroups] = useState([])
  const [classes, setClasses] = useState([])
  const [variety, setVariety] = useState([])
  const [breedCategory, setBreedCategory] = useState([])
  const [furlist, setFurlist] = useState([])
  const [furData, setFurData] = useState([])
  const [furListType, setFurListType] = useState([])
  //
  useEffect(() => {
    // when user change dropdown value then set by default value
    // setBreedCategory([])
    if (!list.show_id) setBreedCategory([])
    if (!list.breed_category_id) setBreeds([])
    if (!list.breed_id) setClasses([])
    if (!list.group_id) setGroups([])
    if (!list.variety_id) setVariety([])
    if (!list.breed_id) setFurData([])

    // type 1, Manual Entry 2, Easy Entry
    if (list.type === 2) {
      if (list.previewScreen) {
        get(`/api/v1/breed/category/all`).then((res) => {
          setBreedCategory(res?.data || [])
        })
      } else {
        get(`/api/v1/breed/category/all`).then((res) => {
          setBreedCategory(res?.data || [])
        })
      }

      if (list.breed_category_id) {
        //api/v1/events/1/breeds/breed
        if (list.breed_id) {
          post(`/api/v1/events/${event_id}/breeds/${list.breed_id}/class`, {
            sort_by_class: 1,
          }).then((res) => {
            setClasses(res?.data || [])
          })

          let eventData = JSON.parse(localStorage.getItem('events'))

          let burBody = {
            fur_category: eventData.event_type,
          }
          post(
            `/api/v1/events/${event_id}/breeds/${list.breed_id}/fur_data`,
            burBody
          ).then((res) => {
            hasFurData(index, res?.data || [])
            setFurData(res?.data || [])
            let data = []
            if (Array.isArray(res?.data)) {
              data = res.data.map((res) => {
                return {
                  value: res.fur_type_id,
                  label: res.fur_type_name,
                }
              })
            }

            setFurlist(data)
          })
        }
        if (list.breed_category_id) {
          get(
            `api/v1/breed/all?published=1&breed_category_id=${list.breed_category_id}`
          ).then((res) => {
            setBreeds(res?.data || [])

            if (list.breed_id && Array.isArray(res.data)) {
              let data = res.data.find(
                (res) => Number(res.breed_id) === Number(list.breed_id)
              )

              let { group_seq, variety_seq } = data
              if (group_seq && variety_seq) {
                post(
                  `api/v1/events/${event_id}/breeds/${list.breed_id}/group`
                ).then((res) => {
                  setGroups(res?.data || [])
                })

                post(
                  `api/v1/events/${event_id}/breeds/${list.breed_id}/variety`
                ).then((res) => {
                  let getGroup = Array.isArray(groups)
                    ? groups.find((s) => s.group_id === list.group_id)
                    : []
                  let varietyData = res?.data
                  if (getGroup && Array.isArray(res?.data)) {
                    varietyData = res.data.filter(
                      (s) => s.group_name === getGroup.group_name
                    )
                    setVariety(varietyData)
                  } else {
                    setVariety(varietyData)
                  }
                })
              }
              if (!group_seq && variety_seq) {
                setGroups([])
                post(
                  `api/v1/events/${event_id}/breeds/${list.breed_id}/variety`
                ).then((res) => {
                  setVariety(res?.data || [])
                })
              }
              if (group_seq && !variety_seq) {
                setVariety([])
                post(
                  `api/v1/events/${event_id}/breeds/${list.breed_id}/group`
                ).then((res) => {
                  setGroups(res?.data || [])
                })
              }
              if (!group_seq && !variety_seq) {
                setGroups([])
                setVariety([])
              }
            }
          })
        }
      }
    } else {
      //
      if (list.previewScreen && list.show_id) {
        let showData = {
          show_id_list: [list.show_id],
        }
        post(`/api/v1/events/${event_id}/breeds/breed_category`, showData).then(
          (res) => {
            setBreedCategory(res?.data || [])
          }
        )
      } else {
        if (list.show_id.length) {
          let showData = {
            show_id_list: list.show_id,
          }

          post(
            `/api/v1/events/${event_id}/breeds/breed_category`,
            showData
          ).then((res) => {
            setBreedCategory(res?.data || [])
          })
        } else {
          setBreedCategory([])
        }
      }

      if (list.breed_category_id) {
        //api/v1/events/1/breeds/breed
        if (list.breed_id) {
          post(`/api/v1/events/${event_id}/breeds/${list.breed_id}/class`, {
            sort_by_class: 1,
          }).then((res) => {
            setClasses(res?.data || [])
          })

          let eventData = JSON.parse(localStorage.getItem('events'))

          let burBody = {
            fur_category: eventData.event_type,
          }

          post(
            `/api/v1/events/${event_id}/breeds/${list.breed_id}/fur_data`,
            burBody
          ).then((res) => {
            hasFurData(index, res?.data || [])
            setFurData(res?.data || [])
            let data = []
            if (Array.isArray(res?.data)) {
              data = res.data.map((res) => {
                return {
                  value: res.fur_type_id,
                  label: res.fur_type_name,
                }
              })
            }

            setFurlist(data)
          })
        }
        if (list.breed_category_id) {
          const breedCategoryData = {
            show_id_list: !list.previewScreen
              ? Array.isArray(list.show_id) &&
                list.show_id.map((res) => Number(res))
              : [list.show_id],
            breed_category_id_list: [list.breed_category_id],
          }

          post(
            `api/v1/events/${event_id}/breeds/breed`,
            breedCategoryData
          ).then((res) => {
            setBreeds(res?.data || [])

            if (list.breed_id) {
              if (Array.isArray(res.data)) {
                let data = res.data.find(
                  (res) => Number(res.breed_id) === Number(list.breed_id)
                )
                let { group_seq, variety_seq } = data
                if (group_seq && variety_seq) {
                  post(
                    `api/v1/events/${event_id}/breeds/${list.breed_id}/group`
                  ).then((res) => {
                    setGroups(res?.data || [])
                  })

                  post(
                    `api/v1/events/${event_id}/breeds/${list.breed_id}/variety`
                  ).then((res) => {
                    let getGroup = Array.isArray(groups)
                      ? groups.find((s) => s.group_id === list.group_id)
                      : []
                    let varietyData = res?.data
                    if (getGroup && Array.isArray(res?.data)) {
                      varietyData = res.data.filter(
                        (s) => s.group_name === getGroup.group_name
                      )
                      setVariety(varietyData)
                    } else {
                      setVariety(varietyData)
                    }
                  })
                }
                if (!group_seq && variety_seq) {
                  setGroups([])
                  post(
                    `api/v1/events/${event_id}/breeds/${list.breed_id}/variety`
                  ).then((res) => {
                    setVariety(res?.data || [])
                  })
                }
                if (group_seq && !variety_seq) {
                  setVariety([])
                  post(
                    `api/v1/events/${event_id}/breeds/${list.breed_id}/group`
                  ).then((res) => {
                    setGroups(res?.data || [])
                  })
                }
                if (!group_seq && !variety_seq) {
                  setGroups([])
                  setVariety([])
                }
              } else {
                setGroups([])
                setVariety([])
              }
            }
          })
        }
      }
    }
  }, [
    list.breed_category_id,
    list.breed_id,
    list.group_id,
    list.show_type,
    list.ear_no,
    list.group_seq,
    list.user_id,
    list.variety_seq,
    list.show_id,
    event_id,
    list.previewScreen,
    list.isPreview,
    list.type,
  ])

  useEffect(() => {
    const data = Array.isArray(showData)
      ? showData.map((data) => {
          return {
            show_id: data.show_id,
            show_char: data.show_char,
            show_type: data.show_type,
            show_date: data.show_date,
            char_description: data.char_description,
            char: data.show_char,
            show_category: data.show_category,
          }
        })
      : []

    let finalShow = Array.isArray(data)
      ? data.filter((res) => res.show_type === list.show_type)
      : []
    finalShow = finalShow.map((res) => {
      const postFixShow = `${res.show_category === 'speciality' ? '/ SP' : ''}`

      return {
        value: res.show_id,
        label: (
          <>
            <span>{`${res.char} (${getDate(
              res.show_date
            )}) ${postFixShow}`}</span>
            <Tooltip
              // options
              title={`${res?.char_description}`}
              position='bottom'
              arrow={true}
              distance={20}
              trigger='mouseenter'
            >
              <i
                className='fa fa-info-circle ml-2 '
                // style={{ cursor: 'pointer' }}
                aria-hidden='true'
              ></i>
            </Tooltip>
          </>
        ),
      }
    })
    setListShow(finalShow)
  }, [list.show_type, showData, list.isSelected])
  useEffect(() => {
    let newArray = []

    if (list.fur_type_id.length > 0 && furData.length > 0) {
      for (let i = 0; i < list.fur_type_id.length; i++) {
        const e = list.fur_type_id[i]

        let data = Array.isArray(furData)
          ? furData.find((s) => s.fur_type_id === e)
          : []
        if (data) {
          data.fur_list.forEach((res) => {
            newArray.push({
              fur_id: res.fur_id,
              fur_name: res.fur_name,
            })
          })
        }
      }
    }

    let finalData = _.uniqBy(newArray, function (e) {
      return e.fur_id
    })

    setFurListType(finalData)
  }, [list.has_fur, list.fur_type_id, furData])
  let show_name = {}
  let show_type = {}
  let breed_category_name = {}
  let breed_name = {}
  let class_name = {}
  let variety_name = {}
  let group_name = {}
  let fur_color = {}
  if (list?.previewScreen) {
    if (Array.isArray(showList))
      show_type = showList.find((s) => s.show_type === list?.show_type)
    if (Array.isArray(listShow))
      show_name = listShow.find((s) => s.value === list?.show_id)
    breed_category_name = breedCategory.find(
      (s) => s.breed_category_id === list?.breed_category_id
    )
    if (Array.isArray(breeds))
      breed_name = breeds.find((s) => s.breed_id === list?.breed_id)
    if (Array.isArray(groups))
      group_name = groups.find((s) => s.group_id === list?.group_id)
    if (Array.isArray(variety))
      variety_name = variety.find((s) => s.variety_id === list?.variety_id)
    if (Array.isArray(classes))
      class_name = classes.find((s) => s.class_id === list?.class_id)
    if (Array.isArray(furListType))
      fur_color = furListType.find((s) => s.fur_id === list?.fur_color)
  }
  let error = Array.isArray(validationList)
    ? validationList.find((s) => s.id === list.id)
    : {}

  return (
    <React.Fragment>
      {breakSection && <hr />}
      <Col
        lg={12}
        className={classnames(
          'manual-box mt-5',
          { 'manual-box ': list?.isDisable && list?.previewScreen },
          { 'box-save ': !list?.isDisable && list?.previewScreen },
          { 'preview-page': list?.previewScreen }
        )}

        // style={{ display: `${inputs.isDeleted ? 'none' : 'block'}` }}
      >
        <Row
          className={list.previewScreen && list.isDisable ? 'table-div1' : ''}
        >
          <Col
            lg={list?.previewScreen ? 11 : 10}
            className='manual-row prev-pad'
          >
            <Row
              className={
                list.previewScreen && list.isDisable ? 'table-div' : ''
              }
            >
              {list.previewScreen && list.isDisable ? (
                <div className='preview-class'>{show_type?.show_type}</div>
              ) : (
                <Col lg={3} className='manual-entry mb-4'>
                  <div>
                    <select
                      type='text'
                      name='show_type'
                      id='show_type'
                      disabled={list.easyDisable || list.isDisable}
                      className='form-control form-select text-capitalize'
                      onChange={(e) =>
                        handleChange(index, e, '', '', [], list.easyDisable)
                      }
                      value={list.show_type}
                    >
                      <option value=''>Select Show Type</option>
                      {Array.isArray(showList) &&
                        showList.map((show) => (
                          <option value={show.show_type}>
                            {show.show_type}
                          </option>
                        ))}
                    </select>
                  </div>

                  {error && error?.show_type && (
                    <p className='error'> {error?.show_type}</p>
                  )}
                </Col>
              )}
              {list.previewScreen ? (
                list.isDisable ? (
                  <div className='preview-class'>{show_name?.label}</div>
                ) : list.showList.length ? (
                  <Col lg={3} className='manual-entry mb-4'>
                    <div>
                      <Select
                        className='react-select react-select-primary'
                        classNamePrefix='react-select'
                        options={list.showList}
                        // isMulti
                        isDisabled={list.isDisable}
                        // closeMenuOnSelect={false}
                        // hideSelectedOptions={false}
                        // components={{
                        //     Option,
                        //     MultiValue,
                        //     animatedComponents,
                        // }}
                        value={
                          list.previewScreen
                            ? list.showList.filter(
                                (s) => s.value === list.show_id
                              )
                            : list.showList.filter((s) =>
                                list.show_id.includes(s.value)
                              )
                        }
                        onChange={(value) =>
                          handleChange(
                            index,
                            value,
                            2,
                            'show_id',
                            [],
                            list.easyDisable
                          )
                        }
                        placeholder={'Select Show'}
                      />
                    </div>
                    {error && error?.show_id && (
                      <p className='error'> {error.show_id}</p>
                    )}
                  </Col>
                ) : (
                  <Col lg={3} className='manual-entry mb-4'>
                    <div>
                      <Select
                        className='react-select react-select-primary'
                        classNamePrefix='react-select'
                        options={listShow}
                        isDisabled={list.isDisable}
                        value={
                          list.previewScreen
                            ? listShow.filter(
                                (s) => Number(s.value) === Number(list.show_id)
                              )
                            : listShow.filter((s) =>
                                list.show_id.includes(s.value)
                              )
                        }
                        onChange={(value) =>
                          handleChange(
                            index,
                            value,
                            2,
                            'show_id',
                            [],
                            list.easyDisable
                          )
                        }
                        placeholder={'Select Show'}
                      />
                    </div>
                    {error && error?.show_id && (
                      <p className='error'> {error.show_id}</p>
                    )}
                  </Col>
                )
              ) : list.showList.length ? (
                <Col lg={3} className='manual-entry mb-4'>
                  <div>
                    <Select
                      className='react-select react-select-primary'
                      classNamePrefix='react-select'
                      options={list.showList}
                      isMulti
                      isDisabled={list.isDisable}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option,
                        MultiValue,
                        animatedComponents,
                      }}
                      value={
                        list.previewScreen
                          ? list.showList.find(
                              (s) => Number(s.value) === Number(list.show_id)
                            )
                          : list.showList.filter((s) =>
                              list.show_id.includes(s.value)
                            )
                      }
                      onChange={(value) =>
                        handleChange(
                          index,
                          value,
                          2,
                          'show_id',
                          [],
                          list.easyDisable
                        )
                      }
                      placeholder={'Select Show'}
                    />
                  </div>
                  {error && error?.show_id && (
                    <p className='error'> {error.show_id}</p>
                  )}
                </Col>
              ) : (
                <Col lg={3} className='manual-entry mb-4'>
                  <div>
                    <Select
                      className='react-select react-select-primary'
                      classNamePrefix='react-select'
                      options={listShow}
                      isMulti
                      isDisabled={list.isDisable || list.previewScreen}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option,
                        MultiValue,
                        animatedComponents,
                      }}
                      value={
                        list.previewScreen
                          ? listShow.find((s) => s.value === list.show_id)
                          : listShow.filter((s) =>
                              list.show_id.includes(s.value)
                            )
                      }
                      onChange={(value) =>
                        handleChange(
                          index,
                          value,
                          2,
                          'show_id',
                          [],
                          list.easyDisable
                        )
                      }
                      placeholder={'Select Show'}
                    />
                  </div>
                  {error && error?.show_id && (
                    <p className='error'> {error.show_id}</p>
                  )}
                </Col>
              )}
              {list.isDisable ? (
                <div className='preview-class'>{list?.user_name}</div>
              ) : (
                <Col lg={3} className='manual-entry mb-4'>
                  <div>
                    <select
                      type='text'
                      name='user_id'
                      disabled={list.isDisable}
                      className='form-control form-select'
                      onChange={(e) => handleChange(index, e)}
                      value={list.user_id}
                    >
                      <option value=''>Select Exhibitor</option>
                      {Array.isArray(exhibitorList) &&
                        exhibitorList?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.last_name} {item.first_name}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                  {error && error?.user_id && (
                    <p className='error'> {error.user_id}</p>
                  )}
                </Col>
              )}
              {list.isDisable ? (
                <div className='preview-class'>{list.ear_no}</div>
              ) : (
                <Col lg={3} className='manual-entry mb-4'>
                  <div>
                    <input
                      type='text'
                      name='ear_no'
                      id='ear_no'
                      disabled={list.isDisable}
                      className='form-control '
                      value={list.ear_no}
                      onChange={(e) => {
                        let IsAlNumeric = IsAlphaNumeric(e)

                        if (IsAlNumeric === true || e.target.value === '') {
                          handleChange(index, e)
                        }
                      }}
                      onDrop={() => {
                        return false
                      }}
                      onPaste={() => {
                        return false
                      }}
                      placeholder={'Enter Ear No.'}
                    />
                  </div>
                  {error && error?.ear_no && (
                    <p className='error'> {error.ear_no}</p>
                  )}
                </Col>
              )}
              {list.isDisable ? (
                <div className='preview-class'>
                  {breed_category_name?.category_name}
                </div>
              ) : (
                <Col lg={3} className='manual-entry mb-4'>
                  <div>
                    <select
                      name='breed_category_id'
                      id='breed_category_id'
                      disabled={list.easyDisable || list.isDisable}
                      className='form-control form-select'
                      onChange={(e) => handleChange(index, e)}
                      value={list.breed_category_id}
                    >
                      <option value=''>Select Category</option>
                      {Array.isArray(breedCategory) &&
                        breedCategory?.map((item, index) => {
                          return (
                            <option value={item.breed_category_id} key={index}>
                              {item.category_name}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                  {error && error?.breed_category_id && (
                    <p className='error'> {error.breed_category_id}</p>
                  )}
                </Col>
              )}
              {/* <Col lg={3} className='manual-entry mb-4'>
                                <div>
                                    <select
                                        name='breed_category_id'
                                        id='breed_category_id'
                                        className='form-control form-select'
                                        onChange={(e) => handleChange(index, e)}
                                        value={list.breed_category_id}
                                        disabled={
                                            list.easyDisable || list.isDisable
                                        }>
                                        <option>Select Category</option>
                                        {breedCategory?.map((item, index) => {
                                            return (
                                                <option
                                                    value={
                                                        item.breed_category_id
                                                    }
                                                    key={index}>
                                                    {item.category_name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {error && error?.breed_category_id && (
                                    <p className='error'>
                                        {' '}
                                        {
                                            error
                                                .breed_category_id
                                        }
                                    </p>
                                )}
                            </Col> */}
              {list.isDisable ? (
                <div className='preview-class'>{breed_name?.breed_name}</div>
              ) : (
                <Col lg={3} className='manual-entry mb-4'>
                  <div>
                    <select
                      name='breed_id'
                      disabled={list.easyDisable || list.isDisable}
                      className='form-control form-select'
                      value={list.breed_id}
                      onChange={(e) =>
                        handleChange(index, e, null, null, breeds, furData)
                      }
                    >
                      <option value=''>Select Breed</option>
                      {Array.isArray(breeds) &&
                        breeds?.map((item, index) => {
                          return (
                            <option
                              key={index}
                              group_seq={item.group_seq ? item.group_seq : null}
                              variety_seq={
                                item.variety_seq ? item.variety_seq : null
                              }
                              value={item.breed_id}
                            >
                              {item.breed_name}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                  {error && error?.breed_id && (
                    <p className='error'> {error.breed_id}</p>
                  )}
                </Col>
              )}
              {groups.length > 0 ? (
                list.previewScreen ? (
                  list.isDisable ? (
                    <div className='preview-class'>
                      {group_name?.group_name}
                    </div>
                  ) : (
                    <Col lg={3} className='manual-entry mb-4'>
                      <div>
                        <select
                          type='text'
                          name='group_id'
                          id='group_id'
                          disabled={list.easyDisable || list.isDisable}
                          className='form-control form-select'
                          onChange={(e) => handleChange(index, e)}
                          value={list.group_id}
                        >
                          <option value=''>Select Group</option>
                          {Array.isArray(groups) &&
                            groups?.map((item, i) => {
                              return (
                                <option value={item.group_id} key={i}>
                                  {item.group_name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      {error && error?.group_id && (
                        <p className='error'> {error.group_id}</p>
                      )}
                    </Col>
                  )
                ) : (
                  <Col lg={3} className='manual-entry mb-4'>
                    <div>
                      <select
                        type='text'
                        name='group_id'
                        id='group_id'
                        disabled={list.easyDisable || list.isDisable}
                        className='form-control form-select'
                        onChange={(e) => handleChange(index, e)}
                        value={list?.group_id}
                      >
                        <option value=''>Select Group</option>
                        {Array.isArray(groups) &&
                          groups?.map((item, i) => {
                            return (
                              <option value={item.group_id} key={i}>
                                {item.group_name}
                              </option>
                            )
                          })}
                      </select>
                    </div>
                    {error && error?.group_id && (
                      <p className='error'> {error.group_id}</p>
                    )}
                  </Col>
                )
              ) : (
                list.previewScreen &&
                list.isDisable && <div className='preview-class'>-</div>
              )}
              {/* {groups.length > 0 && (
                                <Col lg={3} className='manual-entry mb-4'>
                                    <div>
                                        <select
                                            type='text'
                                            name='group_id'
                                            id='group_id'
                                            disabled={
                                                list.easyDisable ||
                                                list.isDisable
                                            }
                                            className='form-control form-select'
                                            onChange={(e) =>
                                                handleChange(index, e)
                                            }
                                            value={list.group_id}>
                                            <option>Select Group</option>
                                            {groups?.map((item, i) => {
                                                return (
                                                    <option
                                                        value={item.group_id}
                                                        key={i}>
                                                        {item.group_name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    {error && error??.group_id && (
                                        <p className='error'>
                                            {' '}
                                            {error.group_id}
                                        </p>
                                    )}
                                </Col>
                            )} */}
              {variety.length > 0 ? (
                list.previewScreen ? (
                  list.isDisable ? (
                    <div className='preview-class'>
                      {variety_name?.variety_name}
                    </div>
                  ) : (
                    <Col lg={3} className='manual-entry mb-4'>
                      <div>
                        <select
                          type='text'
                          name='variety_id'
                          id='variety_id'
                          disabled={list.easyDisable || list.isDisable}
                          className='form-control form-select'
                          onChange={(e) => {
                            handleChange(index, e)
                          }}
                          value={list.variety_id}
                        >
                          <option value=''>Select Variety</option>
                          {Array.isArray(variety) &&
                            variety?.map((item, index) => {
                              return (
                                <option value={item.variety_id} key={index}>
                                  {item.variety_name}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      {error && error?.variety_id && (
                        <p className='error'> {error.variety_id}</p>
                      )}
                    </Col>
                  )
                ) : (
                  <Col lg={3} className='manual-entry mb-4'>
                    <div>
                      <select
                        type='text'
                        name='variety_id'
                        id='variety_id'
                        disabled={list.easyDisable || list.isDisable}
                        className='form-control form-select'
                        onChange={(e) => {
                          handleChange(index, e)
                        }}
                        value={list?.variety_id}
                      >
                        <option value=''>Select Variety</option>
                        {Array.isArray(variety) &&
                          variety?.map((item, index) => {
                            return (
                              <option value={item.variety_id} key={index}>
                                {item.variety_name}
                              </option>
                            )
                          })}
                      </select>
                    </div>
                    {error && error?.variety_id && (
                      <p className='error'> {error.variety_id}</p>
                    )}
                  </Col>
                )
              ) : (
                list.previewScreen &&
                list.isDisable && <div className='preview-class'>-</div>
              )}
              {/* {variety.length > 0 && (
                                <Col lg={3} className='manual-entry mb-4'>
                                    <div>
                                        <select
                                            type='text'
                                            name='variety_id'
                                            id='variety_id'
                                            disabled={
                                                list.easyDisable ||
                                                list.isDisable
                                            }
                                            className='form-control form-select'
                                            onChange={(e) => {
                                                handleChange(index, e);
                                            }}
                                            value={list.variety_id}>
                                            <option>Select Variety</option>
                                            {variety?.map((item, index) => {
                                                return (
                                                    <option
                                                        value={item.variety_id}
                                                        key={index}>
                                                        {item.variety_name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    {error && error??.variety_id && (
                                        <p className='error'>
                                            {' '}
                                            {error.variety_id}
                                        </p>
                                    )}
                                </Col>
                            )} */}
              {list.isDisable ? (
                <div className='preview-class'>{class_name?.class_name}</div>
              ) : (
                <Col lg={3} className='manual-entry mb-4'>
                  <div>
                    <select
                      type='select'
                      name='class_id'
                      disabled={list.easyDisable || list.isDisable}
                      className='form-control form-select'
                      value={list.class_id}
                      onChange={(e) => handleChange(index, e)}
                    >
                      <option value=''>Select Class</option>
                      {Array.isArray(classes) &&
                        classes?.map((item, index) => {
                          return (
                            <option value={item.class_id} key={index}>
                              {item.class_name}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                  {error && error?.class_id && (
                    <p className='error'> {error.class_id}</p>
                  )}
                </Col>
              )}
              {/* <Col lg={3} className='manual-entry mb-4'>
                                <div>
                                    <select
                                        type='select'
                                        name='class_id'
                                        className='form-control form-select'
                                        value={list.class_id}
                                        disabled={list.isDisable}
                                        onChange={(e) =>
                                            handleChange(index, e)
                                        }>
                                        <option>Select Class</option>
                                        {classes?.map((item, index) => {
                                            return (
                                                <option
                                                    value={item.class_id}
                                                    key={index}>
                                                    {item.class_name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {error && error??.class_id && (
                                    <p className='error'>
                                        {' '}
                                        {error.class_id}
                                    </p>
                                )}
                            </Col> */}
              {list.fur_data_validation && furlist.length > 0 ? (
                list?.previewScreen ? (
                  list?.isDisable ? (
                    <div className='preview-class'>
                      {Number(list?.has_fur) === 1 && 'Yes'}
                      {Number(list?.has_fur) === 0 && 'No'}
                    </div>
                  ) : (
                    <Col lg={3} className='manual-entry mb-4'>
                      <div>
                        <select
                          type='text'
                          name='has_fur'
                          disabled={list?.isDisable}
                          className='form-control form-select'
                          onChange={(e) => handleChange(index, e)}
                          value={list?.has_fur}
                        >
                          <option value=''>Select Fur</option>
                          <option value={1}>Yes</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                      {error && error?.has_fur && (
                        <p className='error'> {error.has_fur}</p>
                      )}
                    </Col>
                  )
                ) : (
                  <Col lg={3} className='manual-entry mb-4'>
                    <div>
                      <select
                        type='text'
                        name='has_fur'
                        disabled={list?.isDisable}
                        className='form-control form-select'
                        onChange={(e) => handleChange(index, e)}
                        value={list?.has_fur}
                      >
                        <option value=''>Select Fur</option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </select>
                    </div>
                    {error && error?.has_fur && (
                      <p className='error'> {error.has_fur}</p>
                    )}
                  </Col>
                )
              ) : (
                list?.previewScreen && <div className='preview-class'>-</div>
              )}
              {/* {list.fur_data_validation && furlist.length > 0 && (
                                <Col lg={3} className='manual-entry mb-4'>
                                    <div>
                                        <select
                                            type='text'
                                            name='has_fur'
                                            disabled={list.isDisable}
                                            className='form-control form-select'
                                            onChange={(e) =>
                                                handleChange(index, e)
                                            }
                                            value={list.has_fur}>
                                            <option value=''>Select Fur</option>
                                            <option value={1}>Yes</option>
                                            <option value={0}>No</option>
                                        </select>
                                    </div>
                                    {error && error?.has_fur && (
                                        <p className='error'>
                                            {' '}
                                            {error.has_fur}
                                        </p>
                                    )}
                                </Col>
                            )} */}
              {Number(list?.has_fur) === 1 && list?.displayFur ? (
                list?.isDisable ? (
                  <div className='preview-class'>
                    {Array.isArray(list.fur_type_id) &&
                      list?.fur_type_id
                        .map((s) => {
                          let value = furlist?.find((v) => v.value === s)
                          return value?.label
                        })
                        .join(', ')}
                  </div>
                ) : (
                  <Col lg={3} className='manual-entry mb-4'>
                    <div>
                      <Select
                        options={furlist}
                        isMulti
                        name='fur'
                        isDisabled={list?.isDisable}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                          MultiValue,
                          animatedComponents,
                        }}
                        value={
                          Array.isArray(furlist) &&
                          furlist?.filter((s) =>
                            list?.fur_type_id.includes(s.value)
                          )
                        }
                        onChange={(value) =>
                          handleChange(index, value, 2, 'fur_type_id')
                        }
                        placeholder={'Select Fur Type'}
                      />
                    </div>
                    {error && error?.fur_type_id && (
                      <p className='error'> {error.fur_type_id}</p>
                    )}
                  </Col>
                )
              ) : (
                list?.previewScreen &&
                list?.isDisable && <div className='preview-class'>-</div>
              )}{' '}
              {Number(list?.has_fur) === 1 &&
              list?.displayFur &&
              furListType.length ? (
                list?.isDisable ? (
                  <div className='preview-class'>{fur_color?.fur_name}</div>
                ) : (
                  <Col lg={3} className='manual-entry mb-4'>
                    <div>
                      <select
                        type='text'
                        name='fur_color'
                        disabled={list?.isDisable}
                        className='form-control form-select'
                        onChange={(e) => handleChange(index, e)}
                        value={list?.fur_color || ''}
                      >
                        <option value=''>Select Fur Color</option>
                        {Array.isArray(furListType) &&
                          furListType?.map((item) => {
                            return (
                              <option value={item.fur_id}>
                                {item.fur_name}
                              </option>
                            )
                          })}
                      </select>
                    </div>
                    {error && error?.fur_color && (
                      <p className='error'> {error.fur_color}</p>
                    )}
                  </Col>
                )
              ) : (
                list?.previewScreen &&
                list?.isDisable && <div className='preview-class'>-</div>
              )}{' '}
            </Row>
          </Col>
          {list?.previewScreen ? (
            <Col
              lg={list?.previewScreen ? 0 : 2}
              className='manual-select easy-middle-btn'
            >
              <div className='select-box text-center '>
                {index !== 0 ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/img/icons/delete.png`}
                    alt='delete'
                    className='p-r-2 pe-auto mb-4 w-5 res-edit-check'
                    onClick={() => {
                      handleRemove(index)
                    }}
                  />
                ) : null}

                {
                  list?.isDisable ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/img/icons/update.png`}
                      alt='update'
                      onClick={() => {
                        handleEdit(index)
                      }}
                      className='p-r-2 pe-auto mb-4 w-5 res-edit-check'
                    />
                  ) : (
                    //  <i className='fas fa-edit'  onClick={() => { handleEdit(index) }}> </i>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/img/icons/save.png`}
                      alt='update'
                      onClick={() => {
                        handleSave(index)
                      }}
                      className='p-r-3 pe-auto mb-4 w-5 res-edit-check save-hgt'
                    />
                  )
                  // <i className='fas fa-save' onClick={() => { handleSave(index) }} />
                }
              </div>
            </Col>
          ) : (
            <Col
              lg={list?.previewScreen ? 1 : 2}
              className='manual-select easy-middle-btn'
            >
              <div className='select-box text-center '>
                {index !== 0 && (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/img/icons/delete.png`}
                    alt='delete'
                    className='p-r-3 pe-auto'
                    onClick={() => {
                      handleRemove(index)
                    }}
                  />
                )}
                <div className='mb-3 mt-3 select-check'>
                  <input
                    className='form-check-input mr-2'
                    type='checkbox'
                    name='isSelected'
                    value={list?.isSelected}
                    onChange={(e) => handleChange(index, e)}
                    checked={list?.isSelected === 1 ? true : false}
                  />
                  <br />
                  {!Number(list?.isSelected) && (
                    <label className='form-check-label mt-2'>
                      Select Entry
                    </label>
                  )}
                  {Number(list?.isSelected) ? (
                    <label className='form-check-label mt-2'>
                      Click to Undo
                    </label>
                  ) : null}
                </div>{' '}
              </div>
            </Col>
          )}
          {/* {list.previewScreen ? (
                        <Col lg={2} className='manual-select easy-middle-btn'>
                            <div className='select-box text-center '>
                                {index !== 0 && (
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/img/icons/delete.png`}
                                        alt='delete'
                                        className='p-r-3 pe-auto'
                                        onClick={() => {
                                            handleRemove(index);
                                        }}
                                    />
                                )}
                                <br />
                                <button
                                    type='button'
                                    className={classnames(
                                        'btn w-80',
                                        { ' btn-primary': list.isDisable },
                                        {
                                            ' btn-primary btn-easy':
                                                !list.isDisable,
                                        },
                                    )}
                                    onClick={() => {
                                        list.isDisable
                                            ? handleEdit(index)
                                            : handleSave(index);
                                    }}>
                                    {list.isDisable ? 'Edit' : 'Save'}
                                </button>
                            </div>
                        </Col>
                    ) : (
                        <Col lg={2} className='manual-select easy-middle-btn'>
                            <div className='select-box text-center '>
                                <div className='mb-3 mt-3 select-check'>
                                    <input
                                        className='form-check-input mr-2'
                                        type='checkbox'
                                        name='isSelected'
                                        value={1}
                                        onChange={(e) => handleChange(index, e)}
                                        checked={
                                            list.isSelected === 1 ? true : false
                                        }
                                    />
                                    <br />
                                    {!Number(list.isSelected) && (
                                        <label className='form-check-label mt-2'>
                                            Select Entry
                                        </label>
                                    )}
                                    {Number(list.isSelected) ? (
                                        <label className='form-check-label mt-2'>
                                            Click to Undo
                                        </label>
                                    ) : null}
                                </div>{' '}
                            </div>
                        </Col>
                    )} */}
        </Row>
      </Col>
    </React.Fragment>
  )
}
export default ParticipateEasyEntryList
