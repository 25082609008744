import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'

import { connect, useDispatch, useSelector } from 'react-redux'

import { Link, useHistory, useLocation } from 'react-router-dom'

// Import pdf
//import ExhibitorPdf from "./../../assets/pdf/Exhibitor.pdf";

// Redux Store
import {
  getAllEventCart,
  getAllShopCartValue,
  sanctionShoppingCart,
  showRightSidebarAction,
  toggleLeftmenu,
} from '../../store/actions'

//i18n
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

const Header = (props) => {
  const [isActive, setIsActive] = useState(false)

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current)
  }

  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const shoppingCart = useSelector((state) => state.shopping_cart)
  // const User = useS

  const { shopping_product, eventList } = shoppingCart

  const { cart_data } = eventList
  let lastUrl = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  )
  const user = JSON.parse(localStorage.getItem('authUser'))

  let data = {
    user_id: user.id,
  }

  useEffect(() => {
    dispatch(getAllShopCartValue(data))
    dispatch(getAllEventCart(user.id))
    dispatch(sanctionShoppingCart())
  }, [])

  let count = 0
  if (cart_data.length) {
    cart_data.map((res) => {
      count = count + res.total_entry
    })
  }
  count = count + shopping_product.length

  return (
    <React.Fragment>
      <div
        className='header-main header-dark fixed-top'
        style={{ opacity: '1 ' }}
      >
        <div className='navbar navbar-expand-lg navbar-dark'>
          <Container fluid={true} className='auto-fit'>
            <Link className='navbar-brand' to='/auth-events'>
              <img
                className='logo-dark'
                src={'/assets/img/logo/logo.png'}
                title=''
                alt=''
              />
              <img
                className='logo-light'
                src={'/assets/img/logo/logo.png'}
                title=''
                alt=''
              />
            </Link>

            <button
              className='navbar-toggler'
              onClick={handleClick}
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon'></span>
            </button>

            <div
              className={
                isActive
                  ? 'show collapse navbar-collapse'
                  : 'collapse navbar-collapse'
              }
              id='navbarSupportedContent'
            >
              <ul className='navbar-nav ms-auto align-items-center'>
                {/* <li className="nav-item">
                  <Link
                    to="/dashboard"
                    className={`nav-link ${
                      lastUrl == 'dashboard' ? 'active' : ''
                    }`}
                  >
                    Dashboard{' '}
                  </Link>
                </li> */}
                <li className='nav-item'>
                  <a
                    href='https://www.easy2show.com/assets/pdf/Exhibitor_Entry_How_2.pdf'
                    target='_blank'
                    className='nav-link'
                    rel='noreferrer'
                  >
                    General How 2{' '}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='https://www.easy2show.com/assets/pdf/How_2_Easy_Entry.pdf'
                    target='_blank'
                    className='nav-link'
                    rel='noreferrer'
                  >
                    How 2 Easy Entry{' '}
                  </a>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/auth-events'
                    className={`nav-link ${
                      lastUrl === 'auth-events' ? 'active' : ''
                    }`}
                  >
                    Events{' '}
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/easy-entry'
                    className={`nav-link ${
                      lastUrl === 'easy-entry' ? 'active' : ''
                    }`}
                  >
                    Easy Entry{' '}
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/sponsor_sanction'
                    className={`nav-link ${
                      lastUrl === 'sponsor_sanction' ? 'active' : ''
                    }`}
                  >
                    Sponsors
                  </Link>
                  {/* <Link className='nav-link'> </Link> */}
                </li>
                {Boolean(user?.allow_easyfieds) ? (
                  <li className='nav-item'>
                    <Link
                      className='nav-link'
                      to='/easyfides'
                      // onClick={(e) => window.location.reload()}
                    >
                      Easyfieds{' '}
                    </Link>
                  </li>
                ) : null}

                {/* <li className="nav-item">
                  <Link className="nav-link">Reports </Link>
                </li> */}

                <li className='nav-item dropdown res-text-c'>
                  <Link
                    className={`nav-link d-flex  align-items-center ${
                      lastUrl == 'edit-profile' ||
                      lastUrl == 'subuserlist' ||
                      lastUrl == 'change-password'
                        ? 'active'
                        : ''
                    }`}
                    data-toggle='dropdown'
                  >
                    <i className='fa fa-user' aria-hidden='true'></i>
                    <div className='text-break text-truncate ps-2'>
                      {_.truncate(
                        `${user?.last_name || ''} ${user?.first_name || ''}`,
                        {
                          length: 18,
                          omission: '...',
                        }
                      )}
                    </div>
                  </Link>
                  <ul className='dropdown-menu res-dis-view'>
                    <li>
                      {' '}
                      <Link to='/edit-profile'>Profile</Link>{' '}
                    </li>
                    <li>
                      {' '}
                      <Link to='/subuserlist'>Sub Users</Link>{' '}
                    </li>
                    <li>
                      {' '}
                      <Link to='/your_participation'>
                        Your Participation
                      </Link>{' '}
                    </li>
                    <li>
                      {' '}
                      <Link to='/change-password'>Change Password</Link>{' '}
                    </li>
                    <li>
                      {' '}
                      <Link to='/logout'>Logout</Link>{' '}
                    </li>
                  </ul>
                </li>
                <li className='nav-item'>
                  <span
                    style={{ cursor: 'pointer' }}
                    className='nav-link'
                    onClick={() => {
                      history.push({
                        pathname: '/shopping_cart',
                        state: {
                          hasShoppingCart: true,
                          showAdditional: true,
                        },
                      })
                    }}
                  >
                    <i
                      className='fa fa-shopping-cart cart-font1'
                      aria-hidden='true'
                    ></i>
                  </span>
                  <Link to='/shopping_cart'>
                    <p className='cart-qty'>{count}</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
