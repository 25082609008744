import moment from 'moment'

import { v4 as uuidv4 } from 'uuid'
import { getDateWithFormat } from './date_helper'
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function handleDecimalsOnValue(value) {
  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s
  return value.match(regex)[0]
}

export function phoneMaskingForDisplay(value) {
  if (value) {
    return `${value.substring(0, 3)}-${value.substring(3, 6)}-${value.substring(
      6,
      value.length
    )}`
  }
}

export const showListByShowType = (showData, showType) => {
  let final = []
  final = showData
    .filter((s) => s.show_type === showType)
    .sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.show_date) - new Date(b.show_date)
    })
    .map((x) => ({
      label: `${x.show_char} (${moment(getDateWithFormat(x.show_date)).format(
        'MMM Do YYYY'
      )})${x.show_category === 'speciality' ? '/ SP' : ''}`,
      value: x.show_id,
    }))
  return final
}

export const varietyMemo = (variety, group_id) => {
  let final = []
  final = variety.filter((v) => Number(v.group_id) === Number(group_id))
  return final
}

export const resetFromShowType = () => {
  let obj = {
    show_type: '',
    show_id: '',
    user_id: '',
    ear_no: '',
    breed_category_id: '',
    breed_id: '',
    class_id: '',
    variety_id: '',
    group_id: '',
    has_fur: '',
    fur_type_id: [],
    fur_id: '',
    group_seq: '',
    variety_seq: '',
  }
  return obj
}
export const restFromShowList = () => {
  let obj = {
    breed_category_id: '',
    breed_id: '',
    class_id: '',
    variety_id: '',
    group_id: '',
    has_fur: '',
    fur_type_id: [],
    fur_id: '',
    group_seq: '',
    variety_seq: '',
  }
  return obj
}

const furTypeReset = () => {
  let obj = {
    fur_id: '',
  }
  return obj
}
const hasFurReset = () => {
  let furObj = furTypeReset()
  let obj = {
    ...furObj,
    fur_type_id: [],
  }
  return obj
}

const resetFromVariety = () => {
  let obj = {
    ...hasFurReset(),
    class_id: '',
    has_fur: '',
  }
  return obj
}
const resetFromVarietyDeadline = () => {
  let obj = {
    class_id: '',
  }
  return obj
}
const resetFromVarietyOnlySingleFurData = () => {
  let obj = {
    class_id: '',
  }
  return obj
}

const resetFromGroupOnlySingleFurData = () => {
  let obj = {
    ...resetFromVarietyOnlySingleFurData(),
    variety_id: '',
    has_fur: '',
  }
  return obj
}
const resetFromGroup = () => {
  let obj = {
    ...resetFromVariety(),
    variety_id: '',
    has_fur: '',
  }
  return obj
}

const resetFromBreed = () => {
  let obj = {
    ...resetFromGroup(),
    has_fur_data: false,
    group_id: '',
  }
  return obj
}

const resetFromBreedCategory = () => {
  let obj = {
    ...resetFromBreed(),
    breed_id: '',
  }
  return obj
}

const defaultParticipate = () => ({
  list_id: uuidv4(),
  in_cart: 0,
  event_id: '',
  show_id: '',
  show_type: '',
  user_id: '',
  ear_no: '',
  breed_category_id: '',
  breed_id: '',
  class_id: '',
  variety_id: '',
  group_id: '',
  has_fur: '',
  has_fur_data: false,
  isSame: false,
  fur_type_id: [],
  fur_id: '',
  group_seq: '',
  variety_seq: '',
})
export {
  furTypeReset,
  hasFurReset,
  resetFromVariety,
  resetFromGroup,
  resetFromBreed,
  resetFromBreedCategory,
  defaultParticipate,
  resetFromVarietyOnlySingleFurData,
  resetFromGroupOnlySingleFurData,
  resetFromVarietyDeadline,
}
